import {
  Box,
  Button,
  FilledInput,
  FormControl,
  InputAdornment,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { colors } from "src/utils/theme";
import { IoSend } from "../../../../DesignIcons";
import store, { getChat, getConfig, setChat } from "src/store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

function ChatInput({ isDisabled = false }: { isDisabled?: boolean }) {
  const theme = useTheme();
  const t = useTranslation();
  const [message, setMessage] = useState<string>("");
  const messages = useSelector(getChat);
  const isRtl = useSelector(getConfig).config.lang.rtl;

  const sendMessage = (message: string) => {
    if (message?.trim().length === 0) return;
    const nickname = String(store.getState().player.player.nickname);
    console.log("sendMessage::", message);
    store.dispatch(setChat({ nickname, message }));

    /****
      if (message.length > 0) {
        chatWs.send(
          JSON?.stringify({
              type: 'sendToGroup',
              group: player?.chatGroupName || 'chatroom',
              ackId: ackid(),
              dataType: 'json',
              data: {
                event: 'chat',
                data: { message, displayName: player.displayName },
              },
          })
        )
    }
    *****/

    setMessage("");

    const elem = document.getElementById("messagedToScroll");
    if (elem) {
      if (messages.length > 20) {
        const msgs = [...messages];
        msgs.shift();
      } else {
      }
      setTimeout(() => {
        elem.scrollTop = elem.scrollHeight;
      }, 0);
    }
  };
  return (
    <Box>
      <FormControl
        fullWidth
        variant="filled"
        sx={{
          background: theme.palette.mode === "dark" ? "#151120" : "#fff",
          borderRadius: "8px",
          py: 0.2,
        }}
      >
        <FilledInput
          disabled={isDisabled}
          value={message}
          onChange={(
            e: React.KeyboardEvent<HTMLInputElement> & {
              target: HTMLInputElement;
            }
          ) => setMessage(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            e.key === "Enter" ? sendMessage(message) : null
          }
          // placeholder="Enter message"
          placeholder={t("translate.enter_message")}
          sx={{
            backgroundColor: "transparent",
            width: "100%",

            p: 0,
            ".MuiFilledInput-input": {
              padding: "10px 20px",
              border: "none!important",
              fontSize: "16px",
              color: colors.gray[6],
              fontWeight: 600,
            },
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          autoFocus
          disableUnderline
          size={"small"}
          inputProps={{
            maxLength: 100,
          }}
          endAdornment={
            <InputAdornment position="end">
              <Button
                disabled={isDisabled}
                type="submit"
                style={{
                  color: "#FB8111",
                  padding: "0 16px",
                  fontSize: 12,
                  fontWeight: 300,
                  lineHeight: "112%",
                  cursor: "pointer",
                }}
                onClick={() => sendMessage(message)}
                className={isRtl ? "flip-icon" : ""}
              >
                <IoSend />
              </Button>
            </InputAdornment>
          }
          hiddenLabel
        />
      </FormControl>
    </Box>
  );
}

export default ChatInput;
