export enum gameStatuses {
  GAME_INIT = "init",
  GAME_START = "start",
  GAME_END = "end",
}
export enum messageEvents {
  GAMESTART = "gameStart",
  GAMECRASH = "gameCrash",
  GAMEWAIT = "gameWait",
  GAMEDISCONNECT = "gameDisconnect",
  GAMENEWROUND = "gameNewRound",
  GAMEHISTORY = "gameHistory",
  PLAYERHISTORY = "playerHistory",
  GAMEPLAYERS = "gamePlayers",
  GAMETOTALBETS = "gameTotalBets",
  GAMEBETSCLOSED = "gameBetsClosed",
  SYNC = "sync",
  COUNTER = "counter",
  LANG = "lang",
  CONFIG = "config",
}
export enum Languages {
  EN = "en",
  FA = "fa",
  FIL = "fil",
  CN = "cn",
  DA = "da",
  DE = "de",
  ES = "es",
  FI = "fi",
  FR = "fr",
  ID = "id",
  IT = "it",
  JA = "ja",
  KM = "km",
  KR = "kr",
  MT = "mt",
  NL = "nl",
  RO = "ro",
  RU = "ru",
  TH = "th",
  VI = "vi",
  MY = "my",
}

export enum Currencies {
  USD = "usd",
  IRT = "irt",
}
export enum EGameStatus {
  WAIT = "wait",
  START = "start",
  DRAW = "draw",
}

export enum Connections {
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  CONNECTING = "connecting",
}

export interface LunchConfigType {
  code?: number;
  currency: string;
  group: string;
  lang: string;
  message: string;
  minstake: number;
  multibet: boolean;
  nextroundbet: boolean;
  success: boolean;
  wss: string;
  theme: string;
}

export interface Network {
  connection: Connections;
  networkError: string;
  networkMessage: string;
}

export enum Events {
  PLAYER_CONFIG = "playerConfig",
  HISTORY_REQ = "historyRequest",
  HISTORY_RES = "historyResult",

  BALANCE_REQ = "balanceRequest",
  BALANCE_RES = "balanceResult",

  BUY_REQ = "buyRequest",
  BUY_RES = "buyResult",
  SELL_REQ = "sellRequest",
  SELL_RES = "sellResult",
  NICKNAME_REQ = "nicknameRequest",
  NICKNAME_RES = "nicknameResult",

  BACKEND_DATA_PRICE = "backendDataPrice",

  ACTIVE_PLAYERS = "activePlayers",

  LANG = "lang",
  CONFIG = "config",
}

export interface IBackendDataPrice {
  price: number;
}

export interface IPlayerHistory {
  dateTime?: string;
  betAmount: number;
  winAmount: number;
  status: string;
  profit: number;
  profitPercentage: number;
}

export interface IBoxWithText {
  text: string;
  x: number;
  y: number;
  color?: string;
  fillColor?: string;
  borderColor?: string;
}

export interface IAddMarker {
  x: number;
  y: number;
  color: string;
  text: string;
}
