import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getPlayerBuyResult, getPlayerSellResult } from "src/store/store";
import { addBuyMarker } from "../helpers/AddBuyMarkerHelper";
import { addSellMarker } from "../helpers/AddSellMarkerHelper";
import { graphenObj } from "../helpers/RenderChartHelper";
import { IBuySellProps } from "../types";

const BuySellComponents = ({ markerRef }: IBuySellProps) => {
  const playerBuyResult = useSelector(getPlayerBuyResult).buyResult;
  const playerSellResult = useSelector(getPlayerSellResult).sellResult;
  useEffect(() => {
    if (playerBuyResult.success) {
      addBuyMarker({ parent: graphenObj, markerRef, playerBuyResult });
    }
    // eslint-disable-next-line
  }, [playerBuyResult]);

  useEffect(() => {
    if (playerSellResult.success) {
      addSellMarker({ parent: graphenObj, markerRef, playerSellResult });
    }
    // eslint-disable-next-line
  }, [playerSellResult]);

  return <></>;
};

export default BuySellComponents;
