import { Stack, Typography } from "@mui/material";
import { MoneyBagIcon } from "../../../DesignIcons";
import ActiveUsers from "../../../GameBody/components/Footer/components/ActiveUsers";
import CurrentShareValue from "../../../GameBody/components/Footer/components/CurrentShareValue";

// const PlayersCount = () => {
//   return (
//     <Stack direction={"row"} gap={"9px"}>
//       <PlayersIcon />
//       <Typography
//         sx={{
//           fontSize: "12px",
//         }}
//       >
//         151 / 34
//       </Typography>
//     </Stack>
//   );
// };

export const TotalBets = () => {
  return (
    <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
      <MoneyBagIcon />
      <Typography
        sx={{
          fontSize: "10px",
          color: "#0FC943",
          fontWeight: "700",
          lineHeight: "130%",
          whiteSpace: "nowrap",
        }}
      >
        USD 15,000
      </Typography>
    </Stack>
  );
};

function GameInfo() {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      sx={{
        px: "19px",
        py: "7.5px",
      }}
    >
      {/* <PlayersCount />
            <TotalBets /> */}

      <ActiveUsers />
      <CurrentShareValue />
    </Stack>
  );
}

export default GameInfo;
