import axios from "axios";
import { Events } from "src/@types"; 
import { webSocketSender } from "src/websocket/websocket";
import { nicknameResultHandler } from "./nicknameResultHandler";

export const nicknameRequestHandler = async (nickname: string) => {
  try {
    webSocketSender({ nickname }, "nicknameRequest");
    
    const req: {} = {
      hubName: process.env.REACT_APP_TRADING_HUB_NAME,
      groupName: process.env.REACT_APP_PUB_SUB_GROUP,
      message: {
        event: Events.NICKNAME_REQ,
        data: { nickname },
      },
    };
    await axios.post("http://localhost:5000/trading/nicknameRequest", req); 
  } catch (error) {
    nicknameResultHandler({ nickname });
  }
};
