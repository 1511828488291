import { SmoothGraphics } from "@pixi/graphics-smooth";
import * as PIXI from "pixi.js";
import {
  GAME_BACKGROUND_SPEED,
  GRAPH_BOX_HEIGHT,
  GRID_LINE_COLOR,
  GRID_LINE_THICKNESS,
} from "src/data/constants";

export class GridBackground {
  app: PIXI.Application;
  parentWidth: number;
  parentHeight: number;
  isMobile: boolean;
  grid1: SmoothGraphics;
  grid2: SmoothGraphics;
  texture1: PIXI.RenderTexture;
  texture2: PIXI.RenderTexture;
  bg1: PIXI.Sprite;
  bg2: PIXI.Sprite;

  constructor(
    app: PIXI.Application,
    parentWidth: number,
    parentHeight: number, 
  ) {
    this.app = app;
    this.parentWidth = parentWidth;
    this.parentHeight = parentHeight; 
    this.isMobile = PIXI.utils.isMobile?.phone || PIXI.utils.isMobile?.any;

    this.grid1 = new SmoothGraphics();
    this.grid2 = new SmoothGraphics();
    this.drawGrid();

    this.texture1 = this.app.renderer.generateTexture(this.grid1);
    this.texture2 = this.app.renderer.generateTexture(this.grid2);

    this.bg1 = new PIXI.Sprite(this.texture1);
    this.bg2 = new PIXI.Sprite(this.texture2);

    this.init();
  }

  drawGrid() {
    let gridSize = this.parentWidth * (this.isMobile ? 0.1 : 0.15);
    let gridHeight = this.isMobile ? GRAPH_BOX_HEIGHT * 0.35 : GRAPH_BOX_HEIGHT; 

    this.grid1.clear();
    this.grid2.clear();
    const thickness = this.isMobile ? 0.5 : 1;
    this.grid1.lineStyle(GRID_LINE_THICKNESS * thickness, GRID_LINE_COLOR, 0.2);
    this.grid2.lineStyle(GRID_LINE_THICKNESS * thickness, GRID_LINE_COLOR, 0.2);
    let col = 0;
    for (col = 0; col <= this.parentWidth / gridSize + 1; col++) {
      this.grid1.moveTo(col * gridSize, 0);
      this.grid1.lineTo(col * gridSize, this.parentHeight);
      this.grid2.moveTo(col * gridSize, 0);
      this.grid2.lineTo(col * gridSize, this.parentHeight);
    }
    for (let i = 0; i < this.parentHeight / gridHeight + 1; i++) {
      this.grid1.moveTo(0, i * gridHeight);
      this.grid1.lineTo(gridSize * col, i * gridHeight);
      this.grid2.moveTo(0, i * gridHeight);
      this.grid2.lineTo(gridSize * col, i * gridHeight);
    }
  }

  async init() {
    this.bg1.width = this.app.view.width;
    this.bg1.height = this.app.view.height;
    this.bg2.width = this.bg1.width;
    this.bg2.x = this.bg1.width;
    this.bg2.height = this.bg1.height;

    this.app.stage.addChild(this.bg1);
    this.app.stage.addChild(this.bg2);
  }
  update(delta: number) {
    this.bg1.x -= GAME_BACKGROUND_SPEED;
    this.bg2.x -= GAME_BACKGROUND_SPEED;
    if (this.bg1.x + this.bg1.width < 0) {
      this.bg1.x = this.bg2.x + this.bg2.width;
    }
    if (this.bg2.x + this.bg2.width < 0) {
      this.bg2.x = this.bg1.x + this.bg1.width;
    }
  }
  start() {
    this.app.ticker.add(this.update, this);
  }
  stop() {
    this.app.ticker.remove(this.update, this);
    this.app.stage.removeChild(this.bg1);
    this.app.stage.removeChild(this.bg2);
    this.texture1.destroy();
    this.texture2.destroy();
  }
}
