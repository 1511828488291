import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import store from './store/store';
import { theme } from './utils/theme';



import * as translations from './dictionary'
import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang';

setDefaultTranslations(translations)
setDefaultLanguage('en')


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store} >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
);



