import * as PIXI from "pixi.js";
import { IDrawLine } from "../types";

export const drawLine = ({
  graphen,
  xData,
  yData,
  cap,
  lineWidth,
}: IDrawLine) => {
  const n = xData.length;
  const lineStyleConfig = { 
    width: lineWidth,
    color: 0x1f84ef,
    alpha: 1,
    join: PIXI.LINE_JOIN.ROUND,
    cap,
  };
 
  graphen.clear();
  for (let i = 0; i < n; i++) {
    graphen.lineStyle(lineStyleConfig);
    graphen.moveTo(xData[i], yData[i]);

    const xc_mid = (xData[i] + xData[i + 1]) / 2.0;
    const yc_mid = (yData[i] + yData[i + 1]) / 2;

    const cp_x1 = (xc_mid + xData[i]) / 2;
    const cp_x2 = (xc_mid + xData[i + 1]) / 2;

    if (!isNaN(cp_x2)) {
      graphen.quadraticCurveTo(cp_x1, yData[i], xc_mid, yc_mid);
      graphen.quadraticCurveTo(cp_x2, yData[i + 1], xData[i + 1], yData[i + 1]);
    }
  }
  // graphen.finishPoly(); // in case you didnt use closed paths
  // graphen.geometry.updateBatches();
  // // try commenting that to see Uint16 overflow
  // graphen.geometry._indexBuffer.update(new Uint32Array(graphen.geometry.indicesUint16));
};
