import { createSlice } from '@reduxjs/toolkit' 
import { initialConfig } from '../@types'

export const configSlice = createSlice({
   name: 'config',
   initialState: {
      config: initialConfig,
   },
   reducers: {
      setConfig(state, Action) {
         state.config = Action.payload
      },
   },
})
