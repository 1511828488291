import { createSlice } from "@reduxjs/toolkit";
import { MAX_PLAYER_HISTORY } from "src/data/constants";

export const playerHistorySlice = createSlice({
  name: "playerHistory",
  initialState: { history: [] },
  reducers: {
    setPlayerHistory(state, action) {
      state.history = action.payload?.slice(0, MAX_PLAYER_HISTORY);
    },
  },
});
