import { colors } from "src/utils/theme";
import { Box, FormControl, FormControlProps, FormLabelProps, InputLabel, Stack, StackProps, Typography, TypographyProps, styled, useTheme } from "@mui/material";
import { FC } from 'react';

export const BetFormControl: FC<& FormControlProps> = ({ ...props }) => {
    const theme = useTheme();
    const bgColor = theme.palette.mode === 'dark' ? colors.gray[2] : '#fff';

    return (
        <FormControl
            {...props}
            fullWidth
            variant="filled"
            sx={{
                background: bgColor,
                borderRadius: '8px',
                height: '46px',
            }}
        >
            {props.children}
        </FormControl>
    )
}

export const BetFormLabel: FC<& FormLabelProps> = ({ ...props }) => {


    return (
        <InputLabel
            {...props}
            htmlFor="betAmount"
            sx={{
                color: '#837C9C',
                fontSize: '10px',
                fontWeight: '700 !important',
                left: 0,
            }}
        >
            {props.children}
        </InputLabel>
    )
}

export const BetPlacerStack: FC<StackProps> = ({ ...props }) => {
    const theme = useTheme();
    const bgcolor = theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11]


    return (
        <Stack  dir={'ltr'} direction={"row"} sx={{
            bgcolor: bgcolor,
            alignItems: "center",
            p: 1,
            borderRadius: "8px",
            // justifyContent: "space-between",
            borderColor: theme.palette.mode === "dark" ? "#474060" : "#DCDAE0",
            borderWidth: "1px",
            borderStyle: "solid",
            opacity: 1,
        }} spacing={1}>

            {props.children}
        </Stack>
    )
}


export const StyledIconsBox = styled(Box)(() => ({
    borderColor: colors.gray[4],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
}))


export const DoubleTypo: FC<& TypographyProps> = ({ ...props }) => {
    const theme = useTheme();
    const borderColor = theme.palette.mode === 'dark' ? null : colors.gray[10]

    return (
        <Typography
            {...props}
            sx={{
                fontSize: '12px',
                color: '#FB8111',
                opacity: 1,
                borderColor,
                fontFamily: 'Nunito Sans, sans-serif !important ',
                fontWeight: '700 !important',
            }}
        >
            {props.children}
        </Typography>
    )
}