import store, {
  setPlayer,
  setPlayerBuyResult,
  setPlayerHistory,
  setPlayerSellResult,
  setToast,
} from "src/store/store";
import { calculateCurrentShareValue, initFloat } from "src/helper";
import { IPlayerSellResultState, IPlayerState } from "src/store/@types";
import { globalVars } from "src/data/globals";
import { IPlayerHistory } from "src/@types";

export const sellResultHandler = (sellResult: IPlayerSellResultState) => {
  if (sellResult.nickname !== globalVars.player.nickname) return;

  const player: IPlayerState = store.getState().player.player;
  if (globalVars.player.buyAmount <= 0) return;

  const currentPrice = sellResult.price;
  let currentShareValue = calculateCurrentShareValue(currentPrice);

  const winAmount: number = currentShareValue;
  sellResult.winAmount = winAmount;

  let balance: number = (player.balance as number) + winAmount;
  if (balance <= 0) {
    balance = 0;
  }
  let betAmount: number = Number(player.betAmount);

  globalVars.player.winAmount = winAmount;
  globalVars.player.status =
    globalVars.player.winAmount > globalVars.player.betAmount ? "won" : "lost";

  const profit: number = winAmount - betAmount;
  const profitPercentage: number = (profit / betAmount) * 100;

  const history = [
    {
      betAmount,
      winAmount,
      status: globalVars.player.status,
      profit,
      profitPercentage,
    },
  ];

  const playerHistory: IPlayerHistory[] =
    store.getState().playerHistory.history;
  store.dispatch(setPlayerHistory([...history, ...playerHistory]));

  // globalVars.playerHistory = [...history, ...globalVars.playerHistory];

  globalVars.player.buyAmount = 0;

  //-- set current input bet amount value equivalent to balance if more than the balance
  if (betAmount > balance) { 
    betAmount = Number(initFloat(balance));
  }
  store.dispatch(setPlayer({ balance, betAmount, winAmount }));

  store.dispatch(setPlayerSellResult(sellResult));
  store.dispatch(setPlayerBuyResult({ success: false })); //-- Enable (success:false) / disable (success:true) Buy button

  if (profit > 0) {
    store.dispatch(
      setToast({
        toastType: "win",
        toastMessage: "You have won",
        amount: Number(profit).toFixed(2),
      })
    );
  } else {
    //-- if player lose, no toast will be displayed
    // store.dispatch(
    //   setToast({
    //     toastType: "sell",
    //     toastMessage: "You have sold",
    //     amount: Number(sellResult.winAmount).toFixed(2),
    //   })
    // );
  }
};
