interface SoundItem {
    title: string
    loop?: boolean
    buffer?: AudioBuffer
    source?: AudioBufferSourceNode
 }
 
 const soundsArray: SoundItem[] = [
  
    { title: 'bg_music', loop: true },
 
 ]
 const audioContext = new (window.AudioContext || window?.AudioContext)()
 
 export const preLoadSounds = async (): Promise<void> => {
    for (let i = 0; i < soundsArray.length; i++) {
       const soundItem = soundsArray[i]
       soundItem.buffer = await loadAudioBuffer(
          audioContext,
          `https://cdn.parsiga.co/crash/${soundItem.title}.mp3`
       )
    }
 }
 
 export const unLoadSounds = (): void => {
    for (let i = 0; i < soundsArray.length; i++) {
       const soundItem = soundsArray[i]
       if (soundItem.source) {
          soundItem.source.stop()
       }
       delete soundItem.buffer
    }
 }
 const gainNode = audioContext.createGain()
 
 export const playSound = (title: string): void => {
    const soundItem = soundsArray.find((item) => item.title === title)
 
    if (soundItem && soundItem.buffer) {
       if (soundItem.source) {
          soundItem.source.stop()
       }
       const source = audioContext.createBufferSource()
       source.buffer = soundItem.buffer
       source.loop = soundItem.loop || false
       source.connect(gainNode)
       gainNode.connect(audioContext.destination)
       source.start()
       soundItem.source = source
    }
 }
 export const muteAll = (mute: boolean): void => {
    gainNode.gain.value = mute ? 0 : 1
 }
 
 export const stopSound = (title: string): void => {
    const soundItem = soundsArray.find((item) => item.title === title)
 
    if (soundItem && soundItem.source) {
       soundItem.source.stop()
       soundItem.source = undefined
    }
 }
 
 const loadAudioBuffer = async (
    audioContext: AudioContext,
    url: string
 ): Promise<AudioBuffer> => {
    const response = await fetch(url)
    const audioData = await response.arrayBuffer()
    return new Promise((resolve, reject) => {
       audioContext.decodeAudioData(audioData, resolve, reject)
    })
 }
 