import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { initFloat } from "src/helper";
import { getConfig } from "src/store/store";
import { colors } from "src/utils/theme";

export const PlacedToast = (props: { amount: number, isNextRound: boolean }) => {
    const config = useSelector(getConfig).config;
    const t = useTranslation();
    return (
        <Stack direction={config.lang.rtl ? "row-reverse" : "row"} alignItems={"center"} gap={1} p={.5}>
            <Typography sx={{
                fontSize: "12px",
                // color: "white",
                color: colors.gray[5],
                opacity: "0.03em",
                fontWeight: 600
            }}> {t('translate.congrats_you_won')}</Typography>
            <Typography sx={{
                fontSize: "12px",
                // color: "#fff !important",
                color: colors.gray[5],
                fontWeight: 600
            }}>{config.currency.symbol}{initFloat(props.amount)}    </Typography>
            <Typography sx={{
                fontSize: "10px",
                fontWeight: 700,
                color: "#B9DBFF"
            }}>{props.isNextRound && ('[Next round]')} </Typography>
        </Stack>
    )
}

export const HaveBet = (props: { amount: number }) => {
    const config = useSelector(getConfig).config;
    const t = useTranslation();

    return (
        <Stack direction={config.lang.rtl ? "row-reverse" : "row"} alignItems={"center"} gap={1}>
            <Typography sx={{ fontSize: "12px", color: "#0053AB", fontWeight: 600 }}>{t("translate.you_have_bet")}</Typography>
            <Typography sx={{
                fontSize: "12px",
                color: "white",
                fontWeight: 600
            }}>{config.currency.symbol}{initFloat(props.amount)}    </Typography>
        </Stack>
    )
}


export const WinToast = (props: { amount: number }) => {
    const config = useSelector(getConfig).config;
    const t = useTranslation();

    return (
        <Stack direction={config.lang.rtl ? "row-reverse" : "row"} alignItems={"center"} gap={.5}>
            <Typography
                sx={{ fontSize: "12px", color: "#005415 !important", fontWeight: 600 }}>{t("translate.congrats_you_won")} </Typography>
            <Typography sx={{
                fontSize: "12px",
                fontWeight: 600,
                color: "#005415"
            }}>{config.currency.symbol}{initFloat(props.amount)}    </Typography>
        </Stack>
    )
}

export const RejectedBet = (props: { amount: number, reason?: string }) => {
    const t = useTranslation();

    return (
        <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography
                sx={{ fontSize: "12px", color: "white", fontWeight: 600 }}> {t("translate.your_bet_rejected")}  {props.reason && `, ${props.reason}`}</Typography>
        </Stack>
    )
}