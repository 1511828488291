// import gsap from "gsap";
import { GRAPH_INTERVAL } from "src/data/constants";
import { globalVars } from "src/data/globals";
import { calculateCurrentShareValue, initFloat } from "src/helper";
import { SellStock } from "src/logic";
import store from "src/store/store";

export let yData: { value: number } = { value: 0 };
export let y = 1;

const updateChartData = () => { 
  ////// let price = y + (Math.random() - 0.5) * (Math.random() * 200);
  ////// price = Math.max(0, Math.min(price, 400 + 400)); //TODO:
  const config = store.getState().config.config;

  let currentPrice = Number(globalVars.backendDataPrice);
  //////-- gsap.to(yData, { duration: 0.5, value: currentPrice });
  y = currentPrice;
 
  let input1: HTMLElement | null = document.getElementById("sellButtonText");
  let input2: HTMLElement | null = document.getElementById("currentShareValue");
  if (input1 === null || input2 === null) return;
  input1.innerHTML = "";
  input2.innerHTML = `${config.currency.symbol}0.0`;

  if (globalVars.player.buyAmount <= 0) return;

  let currentShareValue = calculateCurrentShareValue(currentPrice); 
  input1.innerHTML =
    `(${config.currency?.symbol}` +
    initFloat(currentShareValue) +
    ")";
  input2.innerHTML =
    config.currency?.symbol + initFloat(currentShareValue);

  if (currentShareValue <= 0) {
    SellStock(globalVars.player.buyAmount);
  }
};

setInterval(() => {
  updateChartData(); 
}, GRAPH_INTERVAL);
