import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import store, { getToast, setToast } from "src/store/store";
import { TOAST_TRIGGER_TYPES } from "../Chart/types";
import { BuyToast, RejectedToast, SellToast, WinToast } from "./ToastComponent";

const NotiComponent = () => {
  const toast = useSelector(getToast);
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSnackbarHandler = ({
    element,
    className,
  }: {
    element: JSX.Element;
    className: string;
  }) => {
    enqueueSnackbar(element, {
      autoHideDuration: 2000,
      className,
      preventDuplicate: true,
      onClose: (event, reason, key) => {
        store.dispatch(setToast({ toastType: "", toastMessage: "" }));
      },
    });
  };

  const toastTrigger = (type: TOAST_TRIGGER_TYPES) => {
    switch (type) {
      case TOAST_TRIGGER_TYPES.BUY:
        enqueueSnackbarHandler({
          element: <BuyToast amount={toast.amount} />,
          className: "buyToast",
        });
        break;
      case TOAST_TRIGGER_TYPES.SELL:
        enqueueSnackbarHandler({
          element: <SellToast amount={toast.amount} />,
          className: "sellToast",
        });
        break;
      case TOAST_TRIGGER_TYPES.WIN:
        enqueueSnackbarHandler({
          element: <WinToast amount={toast.amount} />,
          className: "winToast",
        });

        break;
      case TOAST_TRIGGER_TYPES.REJECTED:
        enqueueSnackbarHandler({
          element: <RejectedToast amount={toast.amount} />,
          className: "rejectedToast",
        });

        break;
    }
  };

  useEffect(() => {
    if (toast.toastMessage) {
      toastTrigger(toast.toastType as TOAST_TRIGGER_TYPES);
    }
    // eslint-disable-next-line
  }, [toast]);

  return <></>;
};

export default NotiComponent;
