import * as PIXI from "pixi.js";
import { SmoothGraphics } from "@pixi/graphics-smooth";
import {
  IPlayerBuyResultState,
  IPlayerSellResultState,
} from "src/store/@types";

export enum TOAST_TRIGGER_TYPES {
  BUY = "buy",
  SELL = "sell",
  WIN = "win",
  LOSE = "lose",
  REJECTED = "rejected",
}

export interface IDrawLine {
  graphen: SmoothGraphics;
  xData: number[];
  yData: number[];
  cap: PIXI.LINE_CAP;
  // isMobile: boolean;
  lineWidth: number;
}

export interface IUpdateMarkerPositions {
  marker: SmoothGraphics;
  w: number;
  graphen: SmoothGraphics;
}

export interface IRenderChart {
  parentRef: React.MutableRefObject<HTMLDivElement | undefined | null>;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | undefined | null>;
  markerRef: React.MutableRefObject<{ position: IPosition } | undefined>;
  isMobile: boolean;
}

export interface IPosition {
  x: number;
  y: number;
}

export interface IPlayerSellResult {
  success: boolean;
  price: number;
  betAmount: number;
  winAmount: number;
}

export interface IBuyMarker {
  parent: SmoothGraphics;
  markerRef: React.MutableRefObject<{ position: IPosition } | undefined>;
  playerBuyResult: IPlayerBuyResultState;
}
export interface ISellMarker {
  parent: SmoothGraphics;
  markerRef: React.MutableRefObject<{ position: IPosition } | undefined>;
  playerSellResult: IPlayerSellResultState;
}

export interface ISpeedFix {
  renderer: PIXI.Application;
  parent: SmoothGraphics;
}

export interface IBuySellProps {
  markerRef: React.MutableRefObject<{ position: IPosition } | undefined>;
}
