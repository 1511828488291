import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-multi-lang";
import {
  ArrowLeft,
  ArrowRight,
  FaceBookIcon,
  LinkIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "./SvgComponent";
import { InformationModalProps } from "../types/propsType";
import { Config, Game } from "../types/types";
import { colors } from "src/utils/theme";
import store from "src/store/store";

export const SocialIcons = () => {
  return (
    <Stack direction={"row"} gap={2.3} alignItems={"center"}>
      <FaceBookIcon />
      <YoutubeIcon />
      <TelegramIcon />
      <TwitterIcon />
      <LinkIcon />
    </Stack>
  );
};

function GeneralRules(props: { config?: Config; game?: Game }) {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();
  const config = store.getState().config.config;
  const game = store.getState().game.game;

  const generalRulesList = [
    t("info.htp_general_rules_li1"),
    `${t("info.htp_general_rules_li2")} ${config.currency.name}${config.currency.name.toString().length > 1 ? " " : ""}${game.minBetAmount}`,
    t("info.htp_general_rules_li3"),
    t("info.htp_general_rules_li4"),
    t("info.htp_general_rules_li5"),
    `${config.currency.name}${config.currency.name.toString().length > 1 ? " " : ""}${game.maxBetAmount} ${t("info.htp_general_rules_li6")}`,
    t("info.htp_general_rules_li7"),
  ];

  return (
    <Box mt={2} mb={5}>
      <Typography
        variant={"body"}
        mt={"27px"}
        fontWeight={700}
        color={textColor}
      >
        {t("info.htp_general_rules")}
      </Typography>
      <ul style={{ padding: "0 25px", gap: "3px" }}>
        {generalRulesList.map((li) => {
          return (
            <li style={{ color: textColor }} key={li}>
              <Typography variant={"body"} color={textColor}>
                {li}
              </Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

export const HowToPlayScreen: FC<InformationModalProps> = ({
  activePage,
  setActivePage,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  const isRtl = store.getState().config.config.lang.rtl;
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];

  return (
    <Stack>
      <Stack
        direction={"row"}
        gap={1}
        alignItems={"center"}
        mb={1}
        sx={{
          position: "sticky",
          top: 0,
          bgcolor: theme.palette.mode === "dark" ? colors.gray[2] : "#fff",
          pt: 2,
          pb: 1,
        }}
      >
        <IconButton onClick={() => setActivePage("info")}>
          {isRtl ? <ArrowRight /> : <ArrowLeft />}
        </IconButton>
        <Typography
          variant={"headline"}
          color={theme.palette.mode === "light" ? colors.gray[5] : "#fff"}
        >
          {t("info.htp_title")}
        </Typography>
      </Stack>
      <Stack justifyContent={"center"} minHeight={280}>
        <img
          // src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/howToPlay.jpg`}
          src="/assets/game-info/howToPlay.jpg"
          alt="How to play"
        />
      </Stack>

      <Typography
        color={textColor}
        variant={"body"}
        mt={"27px"}
        fontWeight={400}
      >
        {t("info.htp_intro")}
      </Typography>
      <GeneralRules />
    </Stack>
  );
};
