import { Stack, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-multi-lang";
import store from "src/store/store";
import { colors } from "src/utils/theme";
import { IMultiButtonProps, InformationModalProps } from "../types/propsType";
import { ChevronLeft, ChevronRight } from "./SvgComponent";

export const MultiButton: FC<InformationModalProps & IMultiButtonProps> = ({
  activePage,
  setActivePage,
  title,
  target,
}) => {
  const theme = useTheme();
  const isRtl = store.getState().config.config.lang.rtl;
  const tabsColor =
    theme.palette.mode === "light" ? colors.gray[6] : colors.gray[7];
  const bgColor =
    theme.palette.mode === "light"
      ? "linear-gradient(90deg, #EDEDF1 6.09%, rgba(249, 249, 249, 0.92) 94.55%)"
      : "linear-gradient(90deg, #2A243B 0%, #362E4A 105.73%)";
  const border = theme.palette.mode === "light" ? "#DCDAE0" : " #2A243A";
  return (
    <Stack
      direction={"column"}
      sx={{
        background: bgColor,
        height: "48px",
        justifyContent: "center",
        paddingX: "16px",
        borderRadius: "12px",
        border: `1px solid ${border}`,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.85,
        },
      }}
      onClick={() => setActivePage(target)}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography color={tabsColor} variant={"buttonMedium"}>
          {title}
        </Typography>
        {isRtl ? <ChevronLeft /> : <ChevronRight />}
      </Stack>
    </Stack>
  );
};

export const InfoModalButtons: FC<InformationModalProps> = ({
  activePage,
  setActivePage,
}) => {
  const t = useTranslation();
  return (
    <Stack direction={"column"} gap={1}>
      <MultiButton
        title={t("info.htp_title")}
        target={"howToPlay"}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <MultiButton
        title={t("info.gb_title")}
        target={"Board"}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <MultiButton
        title={t("info.pa_title")}
        target={"Actions"}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      <MultiButton
        title={t("info.gt_title")}
        target={"Tabs"}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      {/* <MultiButton
        title={t('info.htp_stake')}  
        target={"stake"}
        activePage={activePage}
        setActivePage={setActivePage}
        /> */}
      {/* <MultiButton
        title={t('info.htp_dashboard')}   
        target={"dashboard"}
        activePage={activePage}
        setActivePage={setActivePage}
      /> */}
    </Stack>
  );
};
