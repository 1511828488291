
// import { colors } from '../themes/dark';

import { colors } from "src/utils/theme"



export const V6Icon = () => {
    return (
        <svg
            width="26"
            height="20"
            viewBox="0 0 26 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.75 2.5C2.41406 2.5 3 3.08594 3 3.75V16.25C3 16.9531 2.41406 17.5 1.75 17.5C1.04688 17.5 0.5 16.9531 0.5 16.25V3.75C0.5 3.08594 1.04688 2.5 1.75 2.5ZM10.1094 5.39062C10.6172 5.85938 10.6172 6.67969 10.1094 7.14844L8.50781 8.75H17.4531L15.8516 7.14844C15.3438 6.67969 15.3438 5.85938 15.8516 5.39062C16.3203 4.88281 17.1406 4.88281 17.6094 5.39062L21.3594 9.14062C21.8672 9.60938 21.8672 10.4297 21.3594 10.8984L17.6094 14.6484C17.1406 15.1562 16.3203 15.1562 15.8516 14.6484C15.3438 14.1797 15.3438 13.3594 15.8516 12.8906L17.4531 11.25H8.50781L10.1094 12.8906C10.6172 13.3594 10.6172 14.1797 10.1094 14.6484C9.64062 15.1562 8.82031 15.1562 8.35156 14.6484L4.60156 10.8984C4.09375 10.4297 4.09375 9.60938 4.60156 9.14062L8.35156 5.39062C8.82031 4.88281 9.64062 4.88281 10.1094 5.39062ZM25.5 3.75V16.25C25.5 16.9531 24.9141 17.5 24.25 17.5C23.5469 17.5 23 16.9531 23 16.25V3.75C23 3.08594 23.5469 2.5 24.25 2.5C24.9141 2.5 25.5 3.08594 25.5 3.75Z"
                fill="#968EB0"
            />
        </svg>
    )
}


export const AutoCashOutIcon = () => {
    return (
        <svg
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.94452 4.45163C1.35718 4.81654 1 5.45897 1 6.15044V10.8496C1 11.541 1.35718 12.1835 1.94452 12.5484L6.44452 15.3442C7.09088 15.7458 7.90912 15.7458 8.55548 15.3442L13.0555 12.5484C13.6428 12.1835 14 11.541 14 10.8496V6.15044C14 5.45897 13.6428 4.81654 13.0555 4.45163L8.55548 1.65577C7.90912 1.25419 7.09088 1.25419 6.44452 1.65577L1.94452 4.45163Z"
                fill="#7A6FF8"
                stroke="#4F42DF"
            />
            <path
                d="M4.53144 10.5L7.08344 4.86H7.92344L10.4754 10.5H9.43544L8.87544 9.196H6.12344L5.57144 10.5H4.53144ZM7.49144 5.972L6.47544 8.38H8.52344L7.50744 5.972H7.49144Z"
                fill="white"
            />
        </svg>
    )
}

export const ManualCashOutIcon = () => {
    return (
        <>
            <svg
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.94452 4.45163C1.35718 4.81655 1 5.45897 1 6.15044V10.8496C1 11.541 1.35718 12.1835 1.94452 12.5484L6.44452 15.3442C7.09088 15.7458 7.90912 15.7458 8.55548 15.3442L13.0555 12.5484C13.6428 12.1835 14 11.541 14 10.8496V6.15044C14 5.45897 13.6428 4.81654 13.0555 4.45163L8.55548 1.65577C7.90912 1.25419 7.09088 1.25419 6.44452 1.65577L1.94452 4.45163Z"
                    fill="#F2659F"
                    stroke="#CB2D6E"
                />
                <path
                    d="M4.65934 11V5.36H5.51534L7.51534 9.048L9.50734 5.36H10.3473V11H9.41934V7.2L7.80334 10.144H7.20334L5.58734 7.216V11H4.65934Z"
                    fill="white"
                />
            </svg>
        </>
    )
}

export const OverIcon = () => {
    return (
        <svg
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11 0.75L11 8.25C11 8.6875 10.6562 9 10.25 9C9.8125 9 9.5 8.6875 9.5 8.25V2.5625L2.28125 9.78125C2.125 9.9375 1.9375 10 1.75 10C1.53125 10 1.34375 9.9375 1.21875 9.78125C0.90625 9.5 0.90625 9.03125 1.21875 8.75L8.4375 1.5L2.75 1.5C2.3125 1.5 2 1.1875 2 0.75C2 0.34375 2.3125 0 2.75 0L10.25 0C10.6562 0 11 0.34375 11 0.75Z"
                fill={colors.gray[9]}
            />
        </svg>
    )
}

export const UnderIcon = () => {
    return (
        <svg
            stroke="currentColor"
            fill="#fff"
            strokeWidth="0"
            viewBox="0 0 24 24"
            aria-hidden="true"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M20.03 3.97a.75.75 0 010 1.06L6.31 18.75h9.44a.75.75 0 010 1.5H4.5a.75.75 0 01-.75-.75V8.25a.75.75 0 011.5 0v9.44L18.97 3.97a.75.75 0 011.06 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export const SadFace = () => {
    return (
        <svg
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M48 96C74.4375 96 96 74.625 96 48C96 21.5625 74.4375 0 48 0C21.375 0 0 21.5625 0 48C0 74.625 21.375 96 48 96ZM29.8125 72.9375C29.25 74.625 27.5625 75.375 26.0625 75C24.375 74.4375 23.625 72.75 24 71.25C27.1875 61.3125 36.75 54 48 54C59.0625 54 68.625 61.3125 71.8125 71.25C72.1875 72.75 71.4375 74.4375 69.75 75C68.25 75.375 66.5625 74.625 66 72.9375C63.75 65.625 56.625 60 48 60C39.1875 60 32.0625 65.625 29.8125 72.9375ZM39 39C39 42.375 36.375 45 33 45C29.625 45 27 42.375 27 39C27 35.8125 29.625 33 33 33C36.375 33 39 35.8125 39 39ZM63 45C59.625 45 57 42.375 57 39C57 35.8125 59.625 33 63 33C66.375 33 69 35.8125 69 39C69 42.375 66.375 45 63 45Z"
                fill="#B7ACCB"
            />
        </svg>
    )
}

export const BetweenIcon = () => {
    return (
        <svg
            width="16"
            height="9"
            viewBox="0 0 16 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 4.5C16 4.71875 15.9062 4.9375 15.75 5.0625L12.5 8.0625C12.3438 8.1875 12.1562 8.25 12 8.25C11.7812 8.25 11.5938 8.1875 11.4375 8.03125C11.1562 7.71875 11.1562 7.25 11.4688 6.96875L13.3125 5.25H2.65625L4.5 6.96875C4.8125 7.25 4.8125 7.71875 4.53125 8.03125C4.375 8.1875 4.1875 8.25 4 8.25C3.8125 8.25 3.625 8.1875 3.46875 8.0625L0.21875 5.0625C0.0625 4.9375 0 4.71875 0 4.5C0 4.3125 0.0625 4.09375 0.21875 3.96875L3.46875 0.96875C3.78125 0.6875 4.25 0.6875 4.53125 1C4.8125 1.3125 4.8125 1.78125 4.5 2.0625L2.65625 3.75H13.3125L11.4688 2.0625C11.1875 1.78125 11.1562 1.3125 11.4375 1C11.7188 0.6875 12.1875 0.6875 12.5 0.96875L15.75 3.96875C15.9062 4.09375 16 4.3125 16 4.5Z"
                fill="#3C3553"
            />
        </svg>
    )
}

export const DetailsIconButton = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16ZM6.75 10.5H7.5V8.5H6.75C6.3125 8.5 6 8.1875 6 7.75C6 7.34375 6.3125 7 6.75 7H8.25C8.65625 7 9 7.34375 9 7.75V10.5H9.25C9.65625 10.5 10 10.8438 10 11.25C10 11.6875 9.65625 12 9.25 12H6.75C6.3125 12 6 11.6875 6 11.25C6 10.8438 6.3125 10.5 6.75 10.5ZM8 6C7.4375 6 7 5.5625 7 5C7 4.46875 7.4375 4 8 4C8.53125 4 9 4.46875 9 5C9 5.5625 8.53125 6 8 6Z"
                fill="#7F779E"
            />
        </svg>
    )
}

export const CloseIcon = () => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.5 0.25C1.09375 0.25 0 1.38281 0 2.75V15.25C0 16.6562 1.09375 17.75 2.5 17.75H17.5C18.8672 17.75 20 16.6562 20 15.25V2.75C20 1.38281 18.8672 0.25 17.5 0.25H2.5ZM6.83594 5.83594C7.1875 5.48438 7.77344 5.48438 8.125 5.83594L9.96094 7.67188L11.7969 5.83594C12.1875 5.48438 12.7734 5.48438 13.125 5.83594C13.5156 6.22656 13.5156 6.8125 13.125 7.16406L11.2891 9L13.125 10.8359C13.5156 11.2266 13.5156 11.8125 13.125 12.1641C12.7734 12.5547 12.1875 12.5547 11.7969 12.1641L9.96094 10.3281L8.125 12.1641C7.77344 12.5547 7.1875 12.5547 6.83594 12.1641C6.44531 11.8125 6.44531 11.2266 6.83594 10.8359L8.67188 9L6.83594 7.16406C6.44531 6.8125 6.44531 6.22656 6.83594 5.83594Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}

export const InformationIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 20C15.5078 20 20 15.5469 20 10C20 4.49219 15.5078 0 10 0C4.45312 0 0 4.49219 0 10C0 15.5469 4.45312 20 10 20ZM8.4375 13.125H9.375V10.625H8.4375C7.89062 10.625 7.5 10.2344 7.5 9.6875C7.5 9.17969 7.89062 8.75 8.4375 8.75H10.3125C10.8203 8.75 11.25 9.17969 11.25 9.6875V13.125H11.5625C12.0703 13.125 12.5 13.5547 12.5 14.0625C12.5 14.6094 12.0703 15 11.5625 15H8.4375C7.89062 15 7.5 14.6094 7.5 14.0625C7.5 13.5547 7.89062 13.125 8.4375 13.125ZM10 7.5C9.29688 7.5 8.75 6.95312 8.75 6.25C8.75 5.58594 9.29688 5 10 5C10.6641 5 11.25 5.58594 11.25 6.25C11.25 6.95312 10.6641 7.5 10 7.5Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}

export const ChevronRight = () => {
    return (
        <svg
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.6875 7.28125C9.09375 7.65625 9.09375 8.3125 8.6875 8.6875L2.6875 14.6875C2.3125 15.0938 1.65625 15.0938 1.28125 14.6875C0.875 14.3125 0.875 13.6562 1.28125 13.2812L6.5625 8L1.28125 2.6875C0.875 2.3125 0.875 1.65625 1.28125 1.28125C1.65625 0.875 2.3125 0.875 2.6875 1.28125L8.6875 7.28125Z"
                fill="#EDEDF1"
            />
        </svg>
    )
}
export const ChevronLeft = () => {
    return (
        <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M1.3125 8.71875C0.90625 8.34375 0.90625 7.6875 1.3125 7.3125L7.3125 1.3125C7.6875 0.90625 8.34375 0.90625 8.71875 1.3125C9.125 1.6875 9.125 2.34375 8.71875 2.71875L3.4375 8L8.71875 13.3125C9.125 13.6875 9.125 14.3438 8.71875 14.7188C8.34375 15.125 7.6875 15.125 7.3125 14.7188L1.3125 8.71875Z"
        fill="#EDEDF1"
    />
</svg>

 

    )
}
export const ArrowRight = () => {
    return (
        <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.3984 8.85938C17.9062 8.39062 17.9062 7.57031 17.3984 7.10156L11.1484 0.851562C10.6797 0.34375 9.85938 0.34375 9.39062 0.851562C8.88281 1.32031 8.88281 2.14062 9.39062 2.60938L13.4922 6.75H1.5C0.835938 6.75 0.25 7.29688 0.25 8C0.25 8.66406 0.835938 9.25 1.5 9.25H13.4922L9.39062 13.3516C8.88281 13.8203 8.88281 14.6406 9.39062 15.1094C9.85938 15.6172 10.6797 15.6172 11.1484 15.1094L17.3984 8.85938Z"
            fill="#DCDAE0"
        />
    </svg>
    
    )
}

export const ArrowLeft = () => {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.601562 7.14062C0.09375 7.60938 0.09375 8.42969 0.601562 8.89844L6.85156 15.1484C7.32031 15.6562 8.14062 15.6562 8.60938 15.1484C9.11719 14.6797 9.11719 13.8594 8.60938 13.3906L4.50781 9.25H16.5C17.1641 9.25 17.75 8.70312 17.75 8C17.75 7.33594 17.1641 6.75 16.5 6.75H4.50781L8.60938 2.64844C9.11719 2.17969 9.11719 1.35938 8.60938 0.890625C8.14062 0.382812 7.32031 0.382812 6.85156 0.890625L0.601562 7.14062Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}

export const FaceBookIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.75 8C15.75 3.71875 12.2812 0.25 8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 11.875 3.0625 15.0938 6.78125 15.6562V10.25H4.8125V8H6.78125V6.3125C6.78125 4.375 7.9375 3.28125 9.6875 3.28125C10.5625 3.28125 11.4375 3.4375 11.4375 3.4375V5.34375H10.4688C9.5 5.34375 9.1875 5.9375 9.1875 6.5625V8H11.3438L11 10.25H9.1875V15.6562C12.9062 15.0938 15.75 11.875 15.75 8Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}
export const YoutubeIcon = () => {
    return (
        <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1562 1.90625C16.9688 1.15625 16.375 0.5625 15.6562 0.375C14.3125 0 9 0 9 0C9 0 3.65625 0 2.3125 0.375C1.59375 0.5625 1 1.15625 0.8125 1.90625C0.4375 3.21875 0.4375 6.03125 0.4375 6.03125C0.4375 6.03125 0.4375 8.8125 0.8125 10.1562C1 10.9062 1.59375 11.4688 2.3125 11.6562C3.65625 12 9 12 9 12C9 12 14.3125 12 15.6562 11.6562C16.375 11.4688 16.9688 10.9062 17.1562 10.1562C17.5312 8.8125 17.5312 6.03125 17.5312 6.03125C17.5312 6.03125 17.5312 3.21875 17.1562 1.90625ZM7.25 8.5625V3.5L11.6875 6.03125L7.25 8.5625Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}

export const TelegramIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8C15.75 3.75 12.25 0.25 8 0.25ZM11.5625 5.53125C11.4688 6.75 10.9688 9.75 10.6875 11.0938C10.5938 11.6875 10.375 11.875 10.1562 11.9062C9.71875 11.9375 9.375 11.5938 8.9375 11.3125C8.25 10.875 7.875 10.5938 7.21875 10.1562C6.4375 9.65625 6.9375 9.375 7.375 8.9375C7.5 8.8125 9.46875 7 9.5 6.84375C9.53125 6.8125 9.53125 6.75 9.46875 6.6875C9.4375 6.65625 9.375 6.6875 9.3125 6.6875C9.25 6.6875 8.15625 7.40625 6.0625 8.84375C5.75 9.0625 5.46875 9.15625 5.21875 9.15625C4.9375 9.15625 4.40625 9 4 8.875C3.53125 8.71875 3.125 8.625 3.15625 8.375C3.1875 8.21875 3.375 8.09375 3.75 7.9375C6 6.9375 7.5 6.3125 8.25 6C10.4062 5.09375 10.875 4.9375 11.1562 4.9375C11.2188 4.9375 11.375 4.9375 11.4688 5.03125C11.5 5.0625 11.5625 5.15625 11.5625 5.21875C11.5938 5.34375 11.5938 5.4375 11.5625 5.53125Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}

export const TwitterIcon = () => {
    return (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.3438 3.75C14.3438 3.90625 14.3438 4.03125 14.3438 4.1875C14.3438 8.53125 11.0625 13.5 5.03125 13.5C3.15625 13.5 1.4375 12.9688 0 12.0312C0.25 12.0625 0.5 12.0938 0.78125 12.0938C2.3125 12.0938 3.71875 11.5625 4.84375 10.6875C3.40625 10.6562 2.1875 9.71875 1.78125 8.40625C2 8.4375 2.1875 8.46875 2.40625 8.46875C2.6875 8.46875 3 8.40625 3.25 8.34375C1.75 8.03125 0.625 6.71875 0.625 5.125V5.09375C1.0625 5.34375 1.59375 5.46875 2.125 5.5C1.21875 4.90625 0.65625 3.90625 0.65625 2.78125C0.65625 2.15625 0.8125 1.59375 1.09375 1.125C2.71875 3.09375 5.15625 4.40625 7.875 4.5625C7.8125 4.3125 7.78125 4.0625 7.78125 3.8125C7.78125 2 9.25 0.53125 11.0625 0.53125C12 0.53125 12.8438 0.90625 13.4688 1.5625C14.1875 1.40625 14.9062 1.125 15.5312 0.75C15.2812 1.53125 14.7812 2.15625 14.0938 2.5625C14.75 2.5 15.4062 2.3125 15.9688 2.0625C15.5312 2.71875 14.9688 3.28125 14.3438 3.75Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}

export const LinkIcon = () => {
    return (
        <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.40625 4.125C7.15625 2.375 10 2.375 11.75 4.125C13.4375 5.78125 13.5 8.46875 11.9688 10.25L11.7812 10.4375C11.5 10.75 11.0312 10.7812 10.7188 10.5312C10.4062 10.25 10.375 9.78125 10.6562 9.46875L10.8125 9.28125C11.875 8.09375 11.8125 6.3125 10.6875 5.1875C9.53125 4 7.625 4 6.4375 5.1875L2.90625 8.71875C1.75 9.90625 1.75 11.7812 2.90625 12.9688C4.03125 14.0625 5.8125 14.125 7 13.0938L7.1875 12.9375C7.5 12.6562 7.96875 12.6875 8.25 13C8.53125 13.3125 8.5 13.7812 8.1875 14.0625L8 14.2188C6.21875 15.7812 3.53125 15.6875 1.84375 14.0312C0.09375 12.2812 0.09375 9.40625 1.84375 7.65625L5.40625 4.125ZM14.5938 11.9062C12.8125 13.6562 9.96875 13.6562 8.21875 11.9062C6.53125 10.2188 6.46875 7.5625 8 5.78125L8.15625 5.59375C8.4375 5.28125 8.90625 5.25 9.21875 5.53125C9.53125 5.8125 9.5625 6.28125 9.28125 6.59375L9.15625 6.75C8.09375 7.9375 8.15625 9.71875 9.28125 10.8438C10.4375 12 12.3438 12 13.5312 10.8438L17.0625 7.3125C18.2188 6.125 18.2188 4.25 17.0625 3.0625C15.9375 1.96875 14.1562 1.90625 12.9688 2.9375L12.7812 3.09375C12.4688 3.375 12 3.34375 11.7188 3.03125C11.4375 2.71875 11.4688 2.25 11.7812 1.96875L11.9688 1.8125C13.75 0.25 16.4375 0.34375 18.125 2C19.875 3.75 19.875 6.59375 18.125 8.375L14.5938 11.9062Z"
                fill="#DCDAE0"
            />
        </svg>
    )
}
