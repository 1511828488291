import axios from "axios";
import { w3cwebsocket, w3cwebsocket as W3CWebSocket } from "websocket";
import store, { setGame, setNetwork } from "../store/store";
import { Connections } from "src/@types";
import { ackid, lunchTokenConfig } from "src/helper";
import { eventReceiver } from "src/handlers/eventReceiver";
import { activePlayersRequestHandler } from "src/handlers/activePlayersRequestHandler";
import { globalVars } from "src/data/globals";
import { runDemo } from "src/helper/demoHelper";

let group: number | string = 0;
let ws: W3CWebSocket;

export class Websocket {
  webSocket: W3CWebSocket | undefined;
  groupId: number | string;
  userBalance: number;
  // playerClass: any;

  constructor() {
    this.userBalance = 0;
    this.webSocket = undefined;
    this.groupId = 0;
    // this.playerClass = new Player();
  }

  async init() {
    await this.getToke();
  }

  async close() {
    if (ws) {
      ws.close();
    }
  }

  async getToke() {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const prodWebSocket = parameters.get("wss");
    const startGameToken = parameters.get("startGameToken");

    const game = store.getState().game.game;
    const groupP = parameters.get("group");

    try {
      if (startGameToken) {
        // const wssUrl = await axios.post(
        //    `https://apimg.imoon.com/falcon-preprod/falcon/launch`,
        //    {
        //       startGameToken,
        //    }
        // )

        // await axios.post("http://localhost:5000/trading/subscribe");

        const user: string = "user12345";
        const wssUrl: any = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}${user}`
        );

        // console.log("wssUrl >>>", groupP, wssUrl);

        lunchTokenConfig(wssUrl.data);
        this.groupId = wssUrl.data.group;
        group = this.groupId;

        const wssUri = decodeURIComponent(wssUrl.data.wss);
        store.dispatch(
          setGame({
            ...game,
            groupId: this.groupId,
            websocket: wssUri,
          })
        );
        if (wssUri && this.groupId) {
          this.websocketConnection(wssUri);
        }
      }
      if (prodWebSocket) {
        if (groupP) {
          this.groupId = groupP;
          group = groupP;
          store.dispatch(
            setGame({
              ...game,
              groupId: this.groupId,
              websocket: prodWebSocket,
            })
          );
        }
        this.websocketConnection(decodeURIComponent(prodWebSocket));
      }
    } catch (error) {
      globalVars.isOffline = true;
      runDemo()
      console.log("error::", error);
    }
  }

  websocketConnection(socket: string) {
    const network = store.getState().network.network;
    try {
      this.webSocket = new W3CWebSocket(socket, "json.webpubsub.azure.v1");
      ws = this.webSocket;
      this.webSocket.onopen = () => {
        if (this.webSocket !== undefined) {
          store.dispatch(
            setNetwork({ ...network, connection: Connections.CONNECTED })
          );

          this.onOpen();
        }
      };
      this.webSocket.onclose = (e) => {
        store.dispatch(
          setNetwork({ ...network, connection: Connections.DISCONNECTED })
        );
      };
      this.webSocket.onerror = (e) => {
        store.dispatch(
          setNetwork({ ...network, connection: Connections.DISCONNECTED })
        );
      };
    } catch (e) {}
  }

  private onOpen() {
    const socket = this.webSocket as W3CWebSocket;
    // socket.send(
    //    JSON?.stringify({
    //       type: 'joinGroup',
    //       group: this.groupId,
    //       ackId: ackid(),
    //    })
    // )

    let sent = false;
    setTimeout(() => {
      if (!sent) {
        socket.send(
          JSON?.stringify({
            type: "sendToGroup",
            group: this.groupId,
            ackId: ackid(),
            dataType: "json",
            data: {
              // event: "requestHistory",
              event: "requestPlayerHistory",
              data: {},
            },
          })
        );

        sent = true;
      }
      activePlayersRequestHandler(true);
    }, 1000);

    socket.onmessage = (message): void => {
      let messageData = JSON?.parse(message.data?.toString());
      if (messageData.event === "disconnected") {
        const network = store.getState().network;
        store.dispatch(
          setNetwork({
            ...network,
            connectionStatus: Connections.DISCONNECTED,
            connectionMessage: messageData.message,
          })
        );
      }
      this.onMessage(messageData);
    };
  }

  private onMessage(message: any) { 
    eventReceiver(message, this.webSocket as w3cwebsocket);
  }
}

export const webSocketSender = (data: any, event: string): void => {
  if (ws) {
    ws.send(
      JSON?.stringify({
        type: "sendToGroup",
        group: group,
        noEcho: true,
        ackId: ackid(),
        dataType: "json",
        data: { event, data },
      })
    );
  }
};
