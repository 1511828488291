import React from "react";
import Trading from "./pages/trading";
import "./styles.css";
import { useMediaQuery } from "@mui/material";
import { colors, theme } from "./utils/theme";
function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const background =
    theme.palette.mode === "dark"
      ? isMobile
        ? "linear-gradient(180deg, #27242F 0%, #1D1929 100%), #000000"
        : "#13101F"
      : colors.gray[10];

  return (
    <div
      style={{
        // background: isMobile ? "linear-gradient(180deg, #27242F 0%, #1D1929 100%), #000000" : "#13101F"
        background,
      }}
    >
      <Trading />
    </div>
  );
}

export default App;
