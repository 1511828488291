import store, { setActivePlayers } from "src/store/store";
import { globalVars } from "src/data/globals";
import { IActivePlayers } from "src/store/@types";

export const activePlayersResultHandler = (players: IActivePlayers[]) => { 
  //-- sort players
  let sortedPlayers:IActivePlayers[] = sortPlayers(players);

  store.dispatch(setActivePlayers(sortedPlayers));
 
};

const sortPlayers = (players: IActivePlayers[]) => {
  const nickname: string = globalVars.player.nickname;
  const currentPlayer = players.filter((p: IActivePlayers) => p.nickname === nickname);

  const otherPlayers: IActivePlayers[] = players.filter((p: IActivePlayers) => p.nickname !== nickname);
  const winners = otherPlayers.filter((p: IActivePlayers) => p.status === "won");
  // const active = otherPlayers.filter((p: IActivePlayers) => p.betAmount > 0 && p.status === "").sort((a: IActivePlayers, b: IActivePlayers) => parseFloat(b.betAmount) - parseFloat(a.betAmount));
  // const open = otherPlayers.filter((p: IActivePlayers) => p.betAmount <= 0 && p.status === "");
  const losers = otherPlayers.filter((p: IActivePlayers) => p.status === "lost"); 
  return [...currentPlayer, ...winners,   ...losers];
};
