import { SmoothGraphics } from "@pixi/graphics-smooth";
import gsap from "gsap";
import * as PIXI from "pixi.js";
import {
  GRAPH_BACKGROUND,
  GRAPH_BACKGROUND_MOBILE,
  GRAPH_CIRCLE_MARKER_COLOR,
  GRAPH_CIRCLE_MARKER_SIZE,
  GRAPH_HORIZONTAL_LINE_THICKNESS,
  GRAPH_LINE_WIDTH,
  GRAPH_SPEED,
} from "src/data/constants";
import { colors, theme } from "src/utils/theme";
import { Background } from "../components/Background";
import { GridBackground } from "../components/GridBackground";
import { IRenderChart } from "../types";
import { y } from "./ChartDataHelper";
import { drawLine } from "./DrawLineHelper";
import { speedFix } from "./SpeedFix";

export let graphenObj: SmoothGraphics;
export const renderChart = ({
  parentRef,
  canvasRef,
  markerRef,
  isMobile,
}: IRenderChart) => {
  const bgcolor =
    theme.palette.mode === "dark" ? colors.gray[2] : colors.gray[12];
  const horizontalColor =
    theme.palette.mode === "dark" ? colors.gray[5] : colors.gray[2];

  let renderer = new PIXI.Application();
  let graphen = new SmoothGraphics();
  graphenObj = graphen;

  if (parentRef.current && canvasRef.current) {
    const smoothLine = new SmoothGraphics();
    const marker = new SmoothGraphics();
    markerRef.current = marker;

    let w = parentRef.current.offsetWidth;
    let h = parentRef.current.offsetHeight;

    renderer = new PIXI.Application({
      backgroundColor: bgcolor,
      // premultipliedAlpha: !isMobile,
      // antialias: !isMobile, //true,
      premultipliedAlpha: true,
      antialias: true,
      height: h,
      width: w,
      view:
        canvasRef.current ||
        (document.getElementById("gamePlay") as HTMLCanvasElement),
      resolution: window.devicePixelRatio,
      autoDensity: true,
    });

    /******** removed on 2023-07-14
    const background = new Background(
      renderer,
      isMobile ? GRAPH_BACKGROUND_MOBILE : GRAPH_BACKGROUND
      );
      background.stop();
      background.start();
    */

    let gridBackground = new GridBackground(renderer, w, h);
    gridBackground.start();

    // const marker = createCircle(GRAPH_CIRCLE_MARKER_SIZE,GRAPH_CIRCLE_MARKER_COLOR);
    // markerRef.current = marker;

    renderer.ticker.stop();
    renderer.stage.addChild(graphen, smoothLine, marker);
    graphen.x = w * 0.3;
    graphen.y = h * 0.7;

    const resize = () => {
      if (!isMobile && parentRef.current) {
        w = parentRef.current.clientWidth;
        h = parentRef.current.clientHeight * 0.99;
        renderer.renderer.resize(w, h);

        gridBackground.stop();
        gridBackground = new GridBackground(renderer, w, h);
        gridBackground.start();
      }
    };
    window.removeEventListener("resize", resize);
    window.addEventListener("resize", resize);

    const xData: number[] = [0];
    const yData: number[] = [0];

    let dx = 10;
    let x = 0;
    let lineWidth = GRAPH_LINE_WIDTH * (isMobile ? 0.5 : 1);
    const draw = (dx: number, y: number, cap: PIXI.LINE_CAP) => {
      x += dx * 0.9;
      xData.push(x);
      yData.push(-y);
      if (x > w) {
        xData.shift();
        yData.shift();
      }

      drawLine({ graphen, xData, yData, cap, lineWidth });
      graphen.position.x -= dx * 0.7;
    };

    marker.beginFill(GRAPH_CIRCLE_MARKER_COLOR);
    marker.drawCircle(0, 0, GRAPH_CIRCLE_MARKER_SIZE);
    marker.endFill();

    renderer.ticker.maxFPS = 60;
    renderer.ticker.start();
    gsap.ticker.fps(GRAPH_SPEED);
    gsap.ticker.add(() => {
      //-- speed fix when ticker.FPS falls below 70%
      speedFix({ renderer, parent: graphenObj });

      draw(dx, y, PIXI.LINE_CAP.ROUND);
      marker.position.x = graphen.x + xData[xData.length - 1];
      marker.position.y = graphen.y + yData[yData.length - 1];

      //----- redraw horizontal line
      smoothLine.position.y = marker.y;
      smoothLine.clear();
      smoothLine.lineStyle(GRAPH_HORIZONTAL_LINE_THICKNESS, horizontalColor);
      smoothLine.moveTo(0, 0);
      smoothLine.lineTo(w, 0);

      if (marker.position.y > h * 0.9) {
        const dy = marker.position.y - h * 0.5;
        gsap.to([graphen, smoothLine, marker], {
          duration: 0.5,
          y: "-=" + dy,
        });
      } else if (marker.position.y < h * 0.1) {
        const dy = h * 0.5 - marker.position.y;

        gsap.to([graphen, smoothLine, marker], {
          duration: 0.5,
          y: "+=" + dy,
        });
      }

      if (marker.position.x > w * 0.8) {
        const dx = marker.position.x - w * 0.8;
        graphen.x -= dx;
        marker.x -= dx;
      } else if (marker.position.x < w * 0.2) {
        const dx = w * 0.2 - marker.position.x;
        graphen.x += dx;
        marker.x += dx;
      }

      renderer.ticker.update();
    });
  }
};
