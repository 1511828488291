import { DoubleTypo, StyledIconsBox } from "../@styles";
import { colors } from "src/utils/theme";
import { useTheme } from "@mui/material";
import { SetBetDouble } from "src/logic";
import store from "src/store/store";

const DoubleButton = () => {
  const theme = useTheme();
  const borderColor = theme.palette.mode === "dark" ? null : colors.gray[10];
 
  return (
    <StyledIconsBox 
    className="nonSelect"
      onClick={()=> {!store.getState().playerBuyResult.buyResult.success && SetBetDouble()}}
      sx={{
        width: "46px",
        height: "46px",
        justifyContent: "center",
        borderColor,
      }}
    >
      <DoubleTypo>2x</DoubleTypo>
    </StyledIconsBox>
  );
};

export default DoubleButton;
