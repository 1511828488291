import { initFloat } from "src/helper";
import { eventSender } from "src/listeners/messageListener";
import { IPlayerState } from "src/store/@types";
import store, { setPlayer } from "src/store/store";

export const BuyStock = (betAmount: number) => {
  eventSender("buyRequest", { betAmount });
};

export const SellStock = (betAmount: number) => {
  eventSender("sellRequest", { betAmount });
};

export const SetBetMax = () => {
  const game = store.getState().game.game; 
  let betAmount: number = Number(store.getState().player?.player?.balance);
  if (betAmount > Number(game.maxBetAmount)) {
    betAmount = Number(game.maxBetAmount);
  }
  // Update bet amount equivalent to current balance
  store.dispatch(setPlayer({ betAmount: initFloat(betAmount).replace(",","") }));
};

export const SetBetHalf = () => {
  let betAmount: number = Number(
    (Number(store.getState().player.player.betAmount) / 2).toFixed(2)
  );
  // Half bet amount
  if (parseInt(betAmount.toFixed(0)) <= 1) {
    betAmount = 1;
  }
  store.dispatch(setPlayer({ betAmount }));
};

export const SetBetDouble = () => {
  const player: IPlayerState = store.getState().player.player;
  const balance: number = Number(player.balance);
  const game = store.getState().game.game;
  let betAmount: number = Number((Number(player.betAmount) * 2).toFixed(2));
  // Double bet amount with a maximum value of current balance
  if (betAmount > balance) {
    betAmount = Number(initFloat(balance).replace(",",""));
  }
  if (betAmount > Number(game.maxBetAmount)) {
    betAmount = Number(game.maxBetAmount);
  }
  store.dispatch(setPlayer({ betAmount }));
};

export const UpdateBetAmount = (betAmount: string) => {
  const game = store.getState().game.game;
  const balance: number = Number(store.getState().player.player?.balance);

  const arrBetAmount: string[] = betAmount?.split(".");
  if (betAmount.startsWith("0") || isNaN(Number(betAmount))) return;
  if (arrBetAmount.length > 2 || (arrBetAmount.length > 1 && arrBetAmount[1].length > 2)) return;

  if (Number(betAmount) > Number(game.maxBetAmount)) {
    // Update bet amount and must not exceed current balance and maxBetAmount
    store.dispatch(setPlayer({ betAmount: Number(game.maxBetAmount) }));
  } else if (Number(betAmount) < balance) {
    store.dispatch(setPlayer({ betAmount }));
  } else {
    store.dispatch(setPlayer({ betAmount: initFloat(balance).replace(",","") }));
  }
};
