import { colors } from "src/utils/theme";
import { Stack, Typography, useTheme } from "@mui/material";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getPlayer } from "src/store/store";
import React from "react";

const DisplayName = () => {
  const theme = useTheme();
  const nickname: string = String(useSelector(getPlayer).player.nickname);
  return (
    <Stack
      className={"nonSelect"}
      direction={"row"}
      sx={{ gap: "4px", alignItems: "center" }}
    >
      <FaUser
        color={theme.palette.mode === "dark" ? colors.gray[5] : colors.gray[9]}
        size={16}
      />
      <Typography
        component={"span"}
        variant={"bodySmall"}
        fontWeight={"700 !important"}
        fontSize={"14px"}
        color={theme.palette.mode === "dark" ? colors.gray[8] : colors.gray[6]}
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "100px",
        }}
      >
        {/* Mr Dev */}
        {nickname}
      </Typography>
    </Stack>
  );
};

export default React.memo(DisplayName);
