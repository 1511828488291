import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IPlayerHistory } from "src/@types";
import HistoryModal from "src/modals/HistoryModal";
import { getPlayerHistory } from "src/store/store";

const HistorySelector = () => {
  const [historyItem, setHistoryItem] = useState<IPlayerHistory>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const playerHistory = useSelector(getPlayerHistory).history;

  return (
    <Stack
      direction={"row"}
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <Stack direction={"row"} gap={1}>
        {playerHistory
          ?.filter((p: IPlayerHistory) => p.profitPercentage !== undefined)
          .slice(0, 5)
          .reverse()
          .map((item: IPlayerHistory, index) => (
            <Stack
              onClick={() => {
                setIsModalOpen(true);
                setHistoryItem(item);
              }}
              key={index}
              sx={{
                background: item.profitPercentage > 0 ? "#0AAD36" : "#3C3553",
                width: "fit-content",
                minWidth: "50px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                height: "fit-content",
                minHeight: "24px",
                cursor: "pointer",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                }}
              >
                {Math.abs(item.profitPercentage).toFixed(2)}
              </Typography>
            </Stack>
          ))}
      </Stack>
      <HistoryModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        data={historyItem as IPlayerHistory}
      />
    </Stack>
  );
};

export default React.memo(HistorySelector);
