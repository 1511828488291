import { DoubleTypo, StyledIconsBox } from "../@styles";
import { colors } from "src/utils/theme";
import { useTheme } from "@mui/material";
import { SetBetHalf } from "src/logic";
import store from "src/store/store";

const HalfButton = () => {
  const theme = useTheme();
  const borderColor = theme.palette.mode === "dark" ? null : colors.gray[10]; 

  return (
    <StyledIconsBox
    className="nonSelect"
      onClick={() => {
        !store.getState().playerBuyResult.buyResult.success && SetBetHalf();
      }}
      sx={{
        width: "46px",
        height: "46px",
        justifyContent: "center",
        borderColor,
      }}
    >
      <DoubleTypo>1/2</DoubleTypo>
    </StyledIconsBox>
  );
};

export default HalfButton;
