import { Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { IPlayerHistory } from "src/@types";
import { getPlayerHistory } from "src/store/store";
import { colors, theme } from "src/utils/theme";
import HistoryCard from "./components/HistoryCard";

function History() { 
  
  const bgcolor=theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11];

  const playerHistory: IPlayerHistory[]= useSelector(getPlayerHistory).history;

  return (
    <Stack
      sx={{
        bgcolor,
        border: "1px solid #5B536F",
        maxHeight: ["100%", "230px", "100%"],
        height: "100%",
        borderRadius: "8px",
        overflowY: "scroll",
        p: 1,
      }}
    >
      {/* {players[0]?.playerHistory */}
      {playerHistory?.length>0 && playerHistory
        ?.filter((p: IPlayerHistory) => p.betAmount > 0)
        ?.map((pHistory: IPlayerHistory, index: number) => {
          return (
            <Stack gap={"12px"} mb={1} key={`history-${index}`}>
              <HistoryCard playerHistory={pHistory} />
            </Stack>
          );
        })} 
    </Stack>
  );
}

export default React.memo(History); 

