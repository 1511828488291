import axios from "axios";
import { Events } from "src/@types"; 
import store from "src/store/store";
import { webSocketSender } from "src/websocket/websocket";

export const buyRequestHandler = async (betAmount: string | number | {}) => {
  try {
    const nickname = store.getState().player.player.nickname;
    webSocketSender({ betAmount, nickname }, "buy");
  
    const req: {} = {
      hubName: process.env.REACT_APP_TRADING_HUB_NAME,
      groupName: process.env.REACT_APP_PUB_SUB_GROUP,
      message: {
        event: Events.BUY_REQ,
        data: { betAmount, nickname },
      },
    };
    await axios.post("http://localhost:5000/trading/buyRequest", req);
  } catch (error) { 
  }
};
