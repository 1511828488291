import { Box, Stack, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import Tabs, { TabsTypes } from "./components/Tabs";
import { colors, theme } from "src/utils/theme";
import Players from "../ExtendableComponent/components/TabContentComponents/Players";
import Chat from "../Chat";
import History from "../History";

const DeskTopInfo = () => {
  const [activeTab, setActiveTab] = useState<TabsTypes>(TabsTypes.HISTORY);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const bgcolor =
    theme.palette.mode === "dark"
      ? isMobile
        ? "linear-gradient(180deg, #3C3553 0%, #1E1B2A 90.75%)"
        : colors.gray[4]
      : colors.gray[12];

  return (
    <Stack
      sx={{
        bgcolor,
        width: "100%",
        height: "100%",
        borderRadius: "0px 0px 16px 16px",
      }}
    >
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

      <Box
        sx={{
          height: ["100%", "240px", "500px"],
          marginTop: 1,
          px: 1,
          pb: 1,
        }}
      >
        {/* Tab Content */}

        {activeTab === TabsTypes.PLAYERS && <Players />}
        {activeTab === TabsTypes.CHAT && <Chat />}
        {activeTab === TabsTypes.HISTORY && <History />}
      </Box>
    </Stack>
  );
};

// export default DeskTopInfo;
export default React.memo(DeskTopInfo);
