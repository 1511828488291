import { Table } from "@mui/material";
import { PlayingTableContainer } from "./@style";
import PlayersTableHeader from "./PlayersTableHeader";
import PlayersTableBody from "./PlayersTableBody";
import React from "react";

const Players = () => {
  return (
    <PlayingTableContainer>
      <Table
        stickyHeader
        aria-label="custom pagination table"
        className="playing-table"
      >
        <PlayersTableHeader />
        <PlayersTableBody />
      </Table>
    </PlayingTableContainer>
  );
};

export default React.memo(Players);
