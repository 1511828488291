import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { t } from "react-multi-lang";
import { useSelector } from "react-redux";
import store, { getConfig } from "src/store/store";
import { ActivePlayerCount } from "./ActivePlayers";
import { StatusBadgeToast } from "./StatusBadge";
import { theme } from "src/utils/theme";

const GameChart = () => {
  const isRtl = store.getState().config.config.lang.rtl;
  const config = useSelector(getConfig).config;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: isMobile ? "9.5%" : "9.8%",
          //   right: isRtl ? "14px" : isMobile ? "75px" : "100px",
          right: isRtl ? 0 : 80,
          width: "120px",
          // transform: "scale(1)",
        }}
      >
        {/* <StatusBadgeToast /> */}
      </Box>
      <Stack justifyContent={"center"} minHeight={330}>
        <img
          // src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/gameboard_header.jpg?1`}
          src="/assets/game-info/gameboard_header.png?1"
          alt="game board"
        />
      </Stack>
      {/* <Box
        sx={{
          position: "absolute",
          top: "29%",
          left: isRtl ? "14px" : "85px",
          width: "120px",
          color: "white",
          fontSize: 14,
        }}
      >
        Buy
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "34%",
          right: isRtl ? "14px" : "16%",
          width: "120px",
          color: "white",
          fontSize: 14,
        }}
      >
        Sell
      </Box> */}
      {/* <Box
        sx={{
          position: "absolute",
          bottom: 10,
          left: isRtl ? "14px" : "14px",
          width: "120px",
        }}
      >
        <ActivePlayerCount />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 10,
          right: isRtl ? "14px" : "0px",
          width: "120px",
        }}
      >
        <Typography sx={{ fontSize: 10 }}>
          {t("translate.current_value")}{" "}
          <span style={{ color: "lightgreen" }}>
            {config.currency.symbol}0.0
          </span>
        </Typography>
      </Box> */}
    </Stack>
  );
};

export default GameChart;
