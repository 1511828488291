import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-multi-lang"; 
import { colors } from "src/utils/theme";
import { FaVolumeMute } from "react-icons/fa";
import { StyledIconBox } from "src/@shared";

 
const MuteButton = () => {
    const theme = useTheme();
    const borderColor =
      theme.palette.mode === "dark" ? colors.gray[4] : colors.gray[10];
    const iconColor =
      theme.palette.mode === "dark" ? colors.gray[10] : colors.gray[8];
  
    return (
      <StyledIconBox sx={{ borderColor: borderColor }}>
        <FaVolumeMute color={iconColor} size={16} />
      </StyledIconBox>
    );
  };
  
const ToggleAudio = () => {
    const theme = useTheme();
    const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
    const t = useTranslation();
  
    return (
      <>
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Typography
            sx={{
              background: colors.gray[7],
              // height: 17,
              // width: 17,
              textAlign: "center",
              borderRadius: "50%",
              color: colors.gray[1],
              fontSize: "13px",
              px: "6px",
            }}
          >
            4
          </Typography>
          <Typography color={textColor}>{t("info.gb_toggle_audio")}</Typography>
        </Stack>
        <MuteButton />
  
        <Typography variant={"body"} color={textColor}>
          {t("info.gb_toggle_audio_p1")}
        </Typography>
      </>
    );
  };
  
export default ToggleAudio