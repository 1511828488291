import { Box, Stack, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import store, { getState, setStateTrack } from "src/store/store";
import { colors, theme } from "src/utils/theme";
import BetAmountComponent from "./components/BetAmount";
import BetButton from "./components/BetButton";
import DeskTopInfo from "./components/DeskTopBetInfo/index";
import { ExtendableButton } from "./components/ExtendableComponent";
import GameInfo from "./components/GameInformation";
import React from "react";

const BetBody = () => {
  const stateTracker = useSelector(getState).state;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const bgcolor =
    theme.palette.mode === "dark"
      ? isMobile
        ? "linear-gradient(180deg, #3C3553 0%, #1E1B2A 90.75%)"
        : colors.gray[4]
      : colors.gray[12];

  const handleFloatContent = () => {
    store.dispatch(
      setStateTrack({
        ...stateTracker,
        extendableOpen: !stateTracker.extendableOpen,
      })
    );
  };

  return (
    <Stack
      sx={{
        position: "relative",
        bottom: 0,
        width: "100%",
        height: "100%",
        maxWidth: "800px",
        minWidth: "300px",
      }}
    >
      {isMobile && (
        <Stack>
          <GameInfo />
        </Stack>
      )}
      <Stack
        gap={1.5}
        sx={{ 
          bgcolor:bgcolor,
          p: 1,
          borderRadius: isMobile ? "16px" : "16px 16px 0px 0px",
          maxHeight: "200px",
          zIndex: 600,
        }}
      >
        <BetAmountComponent />
        <BetButton />
        {isMobile && (
          <Box
            sx={{
              position: "absolute",
              top: "11px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
            onClick={handleFloatContent}
          >
            <ExtendableButton isOpen={Boolean(stateTracker.extendableOpen)} />
          </Box>
        )}
      </Stack>
      {!isMobile && <DeskTopInfo />}
 
    </Stack>
  );
};
 
export default BetBody;
