// import { Currencies } from "@crash/common/src/types/types";
import { createSlice } from "@reduxjs/toolkit";
import { globalVars } from "src/data/globals";

export const playerSellResultSlice = createSlice({
  name: "playerSellResult",
  initialState: {
    sellResult: {
      nickname: globalVars.player.nickname,
      success: false,
      price: 0,
      betAmount: 0,
      winAmount: 0,
      // currency: { name: Currencies.USD, symbol: "$", decimal: true },
    },
  },
  reducers: {
    setPlayerSellResult(state, Action) {
      state.sellResult = Action.payload;
    },
  },
});
