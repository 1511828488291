import { Stack, Typography, useTheme } from "@mui/material";
import { FaCoins } from "react-icons/fa";
import { useSelector } from "react-redux";
import { initFloat } from "src/helper";
import { getConfig, getPlayer } from "src/store/store";
import { colors } from "src/utils/theme";

export const PlayerBalance = () => {
  const theme = useTheme();
  const config = useSelector(getConfig).config;
  const playerBalance: number = Number(useSelector(getPlayer).player.balance);

  return (
    <Stack
      className={"nonSelect"}
      direction={"row"}
      sx={{ gap: "4px", alignItems: "center" }}
    >
      <FaCoins
        color={theme.palette.mode === "dark" ? colors.gray[5] : colors.gray[9]}
        size={16}
      />
      <Typography
        component={"span"}
        variant={"bodySmall"}
        fontWeight={"700 !important"}
        fontSize={"14px"}
        fontFamily={"Nunito Sans,sans-serif !important"}
        color={theme.palette.mode === "dark" ? colors.gray[8] : colors.gray[6]}
      >
        {config.currency.symbol}{initFloat(playerBalance)}

      </Typography>
    </Stack>
  );
};
