import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BuyStock, SellStock } from "src/logic";
import { getPlayer, getPlayerBuyResult } from "src/store/store";
import { useTranslation } from "react-multi-lang";

function BetButton() {
  const t = useTranslation();
  const betAmount: number = Number(useSelector(getPlayer).player.betAmount);
  const playerBuyResult = useSelector(getPlayerBuyResult).buyResult;
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  useEffect(() => {
    if (playerBuyResult) {
      setIsLoadingButton(playerBuyResult.betAmount > 0 && !playerBuyResult.success);
      // setIsLoadingButton(!isLoadingButton);

    }
  }, [playerBuyResult]);

  return (
    <Stack direction={"row"} mt={"auto"}>
      <Stack width={"100%"} direction={"row"} gap={1}>
        <LoadingButton
          disabled={playerBuyResult.success || betAmount <= 0}
          loading={isLoadingButton}
          fullWidth
          variant="contained"
          onClick={() => {
            setIsLoadingButton(true);
            BuyStock(betAmount);
          }}
          sx={{
            height: "48px",
            padding: "8px",
            borderRadius: "6px ",
          }}
        >
          <Stack p={1}>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              {isLoadingButton ? "" : t("translate.buy")}
            </Typography>
          </Stack>
        </LoadingButton>

        <Button
          fullWidth
          variant="contained"
          disabled={isLoadingButton || !playerBuyResult.success}
          onClick={() => SellStock(betAmount)}
          sx={{
            borderRadius: "6px",
            background: "#0AAD36",
            borderColor: "#0AAD36",
            ":hover": {
              background: "#0AAD36",
              borderColor: "#0AAD36",
            },
            ":disabled": {
              background: "#0AAD36",
              borderColor: "#0AAD36",
              opacity: 0.5,
            },
            boxShadow: "inset 0px -3px 0px #13621B",
          }}
        >
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              {t("translate.sell")}{" "}
              <span id="sellButtonText">
                {/* Sell amount comes from BetButton->generateY function*/}
              </span>
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </Stack>
  );
}

export default BetButton;
