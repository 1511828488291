import { alpha, Box, InputBase, Tab } from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { colors, gradients } from 'src/utils/theme' 

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      backgroundColor: `#13101E!important`,
      color: colors.gray[6] || theme.palette.grey[300],
      borderBottom: 0,
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: 'white',
      borderBottom: 0,
      fontWeight: 700,
   },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(odd)': {
      backgroundColor:
         theme.palette.mode === 'dark'
            ? 'rgba(74, 71, 84, 0.1)'
            : colors.gray[11],
      borderBottom: 0,
   },
   // hide last border
   '&:last-child td, &:last-child th': {
      border: 0,
   },
}))

export const StyledModalBox = styled(Box)(() => ({
   background: gradients['5'],
}))

export const StyledIconsBox = styled(Box)(() => ({
   borderColor: colors.gray[4],
   borderWidth: '1px',
   borderStyle: 'solid',
   borderRadius: '8px',
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center',
   cursor: 'pointer',
}))

export const StyledAbsoluteCenteredBox = styled(Box)(() => ({
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
}))

export const StyledCenterFlexBox = styled(Box)(() => ({
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}))
export const StyledFlexBox = styled(Box)(() => ({
   display: 'flex',
   flexDirection: 'row',
}))
export const StyledIconBox = styled(Box)(() => ({
   borderColor: colors.gray[4],
   borderWidth: '1px',
   borderStyle: 'solid',
   borderRadius: '8px',
   width: '30px',
   height: '24px',
   display: 'flex',
   flexDirection: 'row',
   alignItems: 'center',
   cursor: 'pointer',
   justifyContent: 'center',
}))

export const StyledTab = styled(Tab)(() => ({
   color: colors.gray[6],
   fontSize: '14px',
   flexShrink: 0,

   flexBasis: 0,
   minWidth: 0,
   fontWeight: 400,
}))

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
   'label + &': {
      marginTop: theme.spacing(1.5),
   },
   '& .MuiInputBase-input': {
      borderRadius: '8px',
      position: 'relative',
      border: `1px solid ${theme.palette.mode === 'light' ? '#DCDAE0' : '#5B536F'}`,
      backgroundColor:
         theme.palette.mode === 'light' ? colors.gray[11] : colors.gray[2],
      // border: '1px solid #ced4da',

      fontSize: 16,
      width: '100%',
      padding: '0px 16px',
      height: '48px',
      transition: theme.transitions.create([
         'border-color',
         'background-color',
         'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
         '-apple-system',
         'BlinkMacSystemFont',
         '"Segoe UI"',
         'Roboto',
         '"Helvetica Neue"',
         'Arial',
         'sans-serif',
         '"Apple Color Emoji"',
         '"Segoe UI Emoji"',
         '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
         boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
         borderColor: theme.palette.primary.main,
      },
   },
}))
