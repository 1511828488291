import {
  Box,
  Stack,
  Table,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { t, useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import { initFloat } from "src/helper";
import { TabsTypes } from "src/pages/trading/components/Game/components/BetBody/components/DeskTopBetInfo/components/Tabs";
import {
  PlayingTableBody,
  PlayingTableCell,
  PlayingTableContainer,
  PlayingTableHeadRow,
  PlayingTableRow,
} from "src/pages/trading/components/Game/components/BetBody/components/ExtendableComponent/components/TabContentComponents/@style";
import {
  ChatIcon,
  HistoryIcon,
  PlayersIcon,
} from "src/pages/trading/components/Game/components/DesignIcons";
import { getConfig } from "src/store/store";
import { colors } from "src/utils/theme";
import { playersTableData } from "./playersTableData";
// import Tabs from "./components/Tabs";
// import Tabs from "../../../../components/tradingInformation/";

export const Playing = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  // const isRtl = useSelector(getConfig).config.lang.rtl;
  const t = useTranslation();
  const bgcolor =
    theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11];

  return (
    <Stack gap={2}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.gt_playing")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.gt_playing_p1")}
      </Typography>
      {/* <img
            src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/gameTabs_header.jpg`}
            alt="Game Tabs"
         /> */}
      <Box
        sx={{
          bgcolor:
            theme.palette.mode === "dark" ? colors.gray[4] : colors.gray[10],
          borderRadius: "12px",
          border: `1px solid ${
            theme.palette.mode === "dark" ? colors.gray[5] : "#D1CDE2"
          }`,
          pointerEvents: "none",
          pb: 1,
        }}
      >
        {/* <Tabs activeTab={activeTab} setActiveTab={setActiveTab} /> */}
        <Stack
          px={1}
          pt={"10px"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          {Object.keys(TabsTypes).map((tab, index) => (
            <Stack
              className="nonSelect"
              sx={{
                cursor: "pointer",
                background:
                  tab.toLowerCase() === TabsTypes.PLAYERS ? bgcolor : "",
                px: [1, 2, 2, 2],
                py: 1,
                borderRadius: "6px",
              }}
              key={index}
              direction={"row"}
              gap={"4px"}
              alignItems={"center"}
            >
              {tab.toLowerCase() === TabsTypes.PLAYERS && (
                <PlayersIcon color={colors.gray[7]} />
              )}
              {tab.toLowerCase() === TabsTypes.CHAT && (
                <ChatIcon color={colors.gray[7]} />
              )}
              {tab.toLowerCase() === TabsTypes.HISTORY && (
                <HistoryIcon color={colors.gray[7]} />
              )}

              <Typography
                sx={{
                  fontSize: ["8.5px","12px","12px"],
                  fontWeight: "700",
                  color: colors.gray[7],
                }}
              >
                {t(`translate.${tab.toLowerCase()}`)}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
      <Box
        sx={{
          bgcolor:
            theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11],
          borderRadius: "12px 12px 0 0",
        }}
      >
        <PlayersBetsTable />
      </Box>
    </Stack>
  );
};
const PlayersBetsTable = () => {
  const config = useSelector(getConfig).config;

  return (
    <PlayingTableContainer>
      <Table
        stickyHeader
        aria-label="custom pagination table"
        className="playing-table"
      >
        <TableHead>
          <TableRow
            sx={{
              borderBottom:
                config.theme.mode === "dark" ? "1px solid #3C3553" : "",
            }}
          >
            <PlayingTableHeadRow
              style={{
                borderRight: `1px solid ${
                  config.theme.mode === "dark" ? "#3C3553" : "#EDEDF1"
                }`,
                width: "23%",
                textAlign: "center",
                borderRadius: "8px 0px 0px 0px",
              }}
            >
              {t("translate.name")}
            </PlayingTableHeadRow>
            <PlayingTableHeadRow
              style={{
                width: "17%",
                borderRight: `1px solid ${
                  config.theme.mode === "dark" ? "#3C3553" : "#EDEDF1"
                }`,
                textAlign: "center",
              }}
            >
              {t("translate.amount")}
            </PlayingTableHeadRow>
            <PlayingTableHeadRow
              style={{
                width: "30%",
                borderRight: `1px solid ${
                  config.theme.mode === "dark" ? "#3C3553" : "#EDEDF1"
                }`,

                textAlign: "center",
              }}
            >
              {t("translate.sell")}
            </PlayingTableHeadRow>
            <PlayingTableHeadRow
              style={{
                width: "30%",
                //   borderRight: `1px solid ${config.theme.mode === "dark" ? '#3C3553' : '#EDEDF1'}`,
                borderRadius: "0px 8px 0px 0px",
                textAlign: "center",
              }}
            >
              {t("translate.profit")}
            </PlayingTableHeadRow>
          </TableRow>
        </TableHead>
        <PlayingTableBody>
          {playersTableData.map((row, index: any) => (
            <PlayingTableRow key={index * Math.random()} id={index}>
              {/* TODO: check nicknameBet class in css files */}
              <PlayingTableCell>
                <span
                  className={"nicknameBet"}
                  style={{
                    color: row.color,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "62px",
                    display: "flow-root",
                    overflow: "hidden",
                    fontWeight: "600 !important",
                  }}
                >
                  {row.nickname}
                </span>
              </PlayingTableCell>

              <PlayingTableCell>
                <span
                  className={"betOdds"}
                  id={`betOdds-${index}`}
                  style={{
                    color: row.color,
                  }}
                >
                  {" "}
                  {row.betAmount ? `${row.betAmount}` : "-"}
                </span>
              </PlayingTableCell>

              <PlayingTableCell>
                <span
                  style={{ display: "none" }}
                  id={`amount-${row.winAmount}`}
                >
                  {row.winAmount}
                </span>
                <span
                  className={"winAmount"}
                  id={`winAmount-${index}`}
                  style={{
                    color: row.color,
                  }}
                >
                  {row.winAmount
                    ? config.currency.symbol + initFloat(row.winAmount)
                    : "-"}
                </span>
              </PlayingTableCell>

              <PlayingTableCell className={`status-${index}`}>
                <span
                  className={"betWin"}
                  id={`win-${row.betAmount}`}
                  style={{
                    color: row.color,
                  }}
                >
                  {" "}
                  {row.winAmount
                    ? `${config.currency.symbol} ${initFloat(row.winAmount)} `
                    : "-"}
                </span>
              </PlayingTableCell>
            </PlayingTableRow>
          ))}
        </PlayingTableBody>
      </Table>
    </PlayingTableContainer>
  );
};
