import { Stack, useMediaQuery } from "@mui/material";
import { colors, theme } from "src/utils/theme";
import Chart from "./components/Chart";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NotiComponent from "./components/ToastComponent/NotiComponent";

function GameBody() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const bgcolor = theme.palette.mode === "dark" ? "#1B1B33" : colors.gray[12];

  return (
    <Stack
      gap={2}
      sx={{
        // px: [0, 0, 0],
        justifyContent: "center",
        position: "relative",
        opacity: 1,
        height: "100%",
      }}
      className="nonSelect"
    >
      <Stack
        sx={{
          // minHeight: ["55vh", "50vh", "45vh"],
          // maxHeight: ["60vh", "55vh", "50vh"],
          height: "100%",
          alignItems: ["center", "", ""],
        }}
      >
        <Stack
          sx={{
            bgcolor: isMobile
              ? "linear-gradient(180deg, #3C3553 0%, #1E1B2A 90.75%)"
              : bgcolor,
            // background: "#1D1929",
            // p: 1,
            borderRadius: "8px",
            border: ["", "1px solid #2A243A"],
            height: "100%",
            width: ["96%", "100%", "100%"],
          }}
        >
          <NotiComponent />
          <Header />
          <Chart />
          {isMobile ? null : <Footer />}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default GameBody;
