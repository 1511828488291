import { useState, useEffect } from 'react';

function useIsMobileLandscape() {
    const [isMobileLandscape, setIsMobileLandscape] = useState(
        window.innerWidth > window.innerHeight && window.innerWidth <= 1024
    );

    useEffect(() => {
        const checkOrientation = () => {
            setIsMobileLandscape(
                window.innerWidth > window.innerHeight && window.innerWidth <= 1024
            );
        };

        window.addEventListener('resize', checkOrientation);
        return () => window.removeEventListener('resize', checkOrientation);
    }, []);

    return isMobileLandscape;
}


export default useIsMobileLandscape