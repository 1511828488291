import { Box, InputBase, alpha, styled } from "@mui/material";
import { colors } from "src/utils/theme";

export const StyledIconBox = styled(Box)(() => ({
    borderColor: colors.gray[4],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '8px',
    width: '33px',
    height: '28px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
}))

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
       marginTop: theme.spacing(1.5),
    },
    '& .MuiInputBase-input': {
       borderRadius: '8px',
       position: 'relative',
       border: `1px solid ${theme.palette.mode === 'light' ? '#DCDAE0' : '#5B536F'}`,
       backgroundColor:
          theme.palette.mode === 'light' ? colors.gray[11] : colors.gray[2],
       // border: '1px solid #ced4da',
 
       fontSize: 16,
       width: '100%',
       padding: '0px 16px',
       height: '48px',
       transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
       ]),
       // Use the system font instead of the default Roboto font.
       fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
       ].join(','),
       '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
       },
    },
 }))