import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-multi-lang";
import ChatInput from "src/pages/trading/components/Game/components/BetBody/components/Chat/components/ChatInput";
import MessageCard from "src/pages/trading/components/Game/components/BetBody/components/Chat/components/MessageCard";
import { IChatState } from "src/store/@types";
import { colors } from "src/utils/theme";

const ChatTab = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === "dark" ? "#fff" : colors.gray[6];
  const t = useTranslation();
  
  const bgcolor =
    theme.palette.mode === "dark" ? colors.gray[3] : colors.gray[11];
  const [messages, setMessages] = useState<IChatState[]>([
    { nickname: "don", message: t("info.gt_chat_message") },
    { nickname: "don", message: t("info.gt_chat_message") },
    { nickname: "don", message: t("info.gt_chat_message") },
  ]);
  return (
    <Stack gap={2}>
      <Typography
        variant="subHeadline"
        color={theme.palette.mode === "dark" ? colors.gray[11] : colors.gray[6]}
      >
        {t("info.gt_chat")}
      </Typography>
      <Typography color={textColor} variant={"body"}>
        {t("info.gt_chat_p1")}
      </Typography>
      {/* <img
        // src={`${process.env.REACT_APP_CDNSERVER}/${theme.palette.mode}/gameTabs_image1.jpg`}
        src="/assets/game-info/gameTabs_image1.png?1"
        alt="Game Tabs"
      /> */}
      <Box
        sx={{
          bgcolor,
          borderRadius: "12px ",
          border: "1px solid #5B536F",
          height: "100%",
        }}
      >
        <Stack
          // height={['370px', '', '100%']}
          height={"100%"}
          direction={"column"}
          sx={{ p: 1.7, justifyContent: "space-between" }}
        >
          <Stack
            id={"messagedToScroll"}
            // maxHeight={"205px"}
            // maxHeight={"205px"}
            className={"nonSelect"}
            sx={{ overflowY: "auto" }}
            gap={1}
            pb={2}
          >
            {messages.map((item, index) => {
              if (!item.nickname) return null;
              return (
                <MessageCard
                  nickname={item.nickname}
                  message={item.message}
                  key={"messages-" + index}
                />
              );
            })}
          </Stack>

          <ChatInput isDisabled={true} />
        </Stack>
      </Box>
    </Stack>
  );
};

export default ChatTab;
