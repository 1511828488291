import { createSlice } from "@reduxjs/toolkit";

import { IPlayer, IPlayerState, initialStatePlayer } from "../@types";

export const playerSlice = createSlice({
  name: "player",
  initialState: initialStatePlayer,
  reducers: {
    setPlayer(state:IPlayer, action) {
      Object.keys(action.payload).map((keyName: string) => { 
        state.player[keyName as keyof IPlayerState] = action.payload[keyName]; 
        return true
      });
    },
  },
});
