export const TicketIcon = () => {




    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="13"
            fill="none"
            viewBox="0 0 18 13"
        >
            <path
                fill="#FCFBFF"
                d="M2 .5h14c1.094 0 2 .906 2 2v8c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2v-8c0-1.094.875-2 2-2zm6.5 4c-.281 0-.5.25-.5.5 0 .281.219.5.5.5h7c.25 0 .5-.219.5-.5 0-.25-.25-.5-.5-.5h-7zM8 8c0 .281.219.5.5.5h7c.25 0 .5-.219.5-.5 0-.25-.25-.5-.5-.5h-7c-.281 0-.5.25-.5.5zM5.125 3.5a.627.627 0 00-.625-.625c-.375 0-.656.281-.656.625v.188c-.156.03-.313.093-.469.156-.469.219-.875.625-1 1.187-.031.313 0 .625.125.907.125.28.344.468.531.625.375.25.844.375 1.219.5h.063c.437.156.718.25.906.375.093.062.125.093.125.125 0 .03.031.093 0 .218 0 .094-.063.188-.25.282-.188.062-.5.093-.907.03-.187-.03-.5-.124-.812-.218l-.188-.094a.683.683 0 00-.812.407c-.094.343.094.687.406.78.031.032.094.032.156.063.22.094.626.219.907.281V9.5c0 .375.281.656.625.656a.64.64 0 00.656-.656v-.156c.156-.031.313-.094.469-.156.5-.188.875-.594 1-1.188.031-.344.031-.656-.094-.938-.125-.28-.344-.468-.531-.625-.375-.28-.907-.437-1.282-.562h-.03c-.438-.125-.72-.219-.907-.344-.094-.062-.125-.093-.125-.125 0 0-.031-.031 0-.156 0-.063.063-.156.25-.25.188-.094.5-.125.906-.063.125 0 .563.094.657.125a.65.65 0 00.78-.437.65.65 0 00-.437-.781c-.156-.031-.468-.094-.656-.125V3.5z"
            ></path>
        </svg>
    )
}


export const DetailsIconButton = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16ZM6.75 10.5H7.5V8.5H6.75C6.3125 8.5 6 8.1875 6 7.75C6 7.34375 6.3125 7 6.75 7H8.25C8.65625 7 9 7.34375 9 7.75V10.5H9.25C9.65625 10.5 10 10.8438 10 11.25C10 11.6875 9.65625 12 9.25 12H6.75C6.3125 12 6 11.6875 6 11.25C6 10.8438 6.3125 10.5 6.75 10.5ZM8 6C7.4375 6 7 5.5625 7 5C7 4.46875 7.4375 4 8 4C8.53125 4 9 4.46875 9 5C9 5.5625 8.53125 6 8 6Z"
                fill="#7F779E"
            />
        </svg>
    )
}