import { createSlice } from '@reduxjs/toolkit'
import { initNetworkState } from '../@types' 

export const networkSlice = createSlice({
   name: 'game',
   initialState: {
      network: initNetworkState,
   },
   reducers: {
      setNetwork(state, Action) {
         state.network = Action.payload
      },
   },
})
