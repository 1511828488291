import numeral from "numeral";
import { EGameStatus, LunchConfigType } from "src/@types";
import { globalVars } from "src/data/globals";
import { Currencies } from "src/store/@types";
import store, { setConfig, setGame } from "src/store/store";

export const gameStatus = () => {
  return store.getState().game.game.gameStatus;
};

export const getGameState = () => {
  return store.getState().game.game;
};

export const updateGameStatus = (gameStatus: EGameStatus) => {
  const game = getGameState();
  store.dispatch(setGame({ ...game, gameStatus }));
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const CountDown = (startTime: number) => {
  let ticker: string | number | NodeJS.Timer | undefined;
  let secondRemaining;

  const countDownStop = () => {
    clearInterval(ticker);
    secondRemaining = 0;
  };

  const countDownStart = (timer: number) => {
    if (gameStatus() !== "start") {
      let timeCalc = 7000 - Number(Date.now() - Number(startTime));
      secondRemaining = ((timeCalc % 60000) / 1000).toFixed(2);

      const counterDom = document.getElementById("countdownText");
      counterDom && (counterDom.style.opacity = "1");
      if (gameStatus() === "start" || Number(secondRemaining) <= 0.1) {
        countDownStop();
        if (counterDom) counterDom.style.opacity = "0";
        return;
      }
      // postMessages.send({ event: 'countDown', data: this.secondRemaining })
      // this.updateCounterTextDom()
      if (counterDom) counterDom.innerText = secondRemaining.toString();
    }
  };

  ticker = setInterval(() => countDownStart(startTime), 1000 / 60);
  if (startTime) {
    secondRemaining = startTime / 1000;
  }
};

export const getCurrencyData = (currency: Currencies) => {
  switch (currency) {
    case Currencies.USD:
      return { name: "usd", symbol: "$", decimal: true };

    case Currencies.IRT:
      return { name: "irr", symbol: "", decimal: false };

    default:
      return {
        name: currency,
        // symbol: ((currency as string) || "").toUpperCase() + " ",
        symbol: ((currency as string) || "").toUpperCase(),
        decimal: true,
      };
  }
};

export const lunchTokenConfig = (config: LunchConfigType) => {
  // new Promise((resolve, reject) => {
  //    const configState = store.getState().config.config
  //    store.dispatch(
  //       setConfig({
  //          ...configState,
  //          theme: { mode: config.theme as 'light' | 'dark' },
  //       })
  //    )
  //    resolve(true)
  // })
  if (config.currency) {
    new Promise((resolve, reject) => {
      const configState = store.getState().config.config;
      store.dispatch(
        setConfig({
          ...configState,
          currency: {
            ...configState.currency,
            name: getCurrencyData(config.currency as Currencies).name,
            symbol: getCurrencyData(config.currency as Currencies).symbol,
            decimal: getCurrencyData(config.currency as Currencies).decimal,
          },
        })
      );
    });
  }
};

export const ackid = (): number => {
  const currGame = store.getState().game.game;
  store.dispatch(
    setGame({
      ...currGame,
      ackId: currGame.ackId + 1,
    })
  );
  return Number(store.getState().game.game.ackId);
};

export const calculateCurrentShareValue = (currentPrice: number) => {
  const buyAmount = globalVars.player.buyAmount;
  const buyPrice = globalVars.player.buyPrice;
  let shareChange = currentPrice- buyPrice;
  if(currentPrice<0){
    shareChange = Math.abs(buyPrice) -Math.abs(currentPrice)  ;
  }
  const changeMultiplier = 0.004 * shareChange;
  let shareValue = buyAmount + changeMultiplier * buyAmount;
  return shareValue > 0 ? shareValue : 0;
};

// let xContainer = new PIXI.Container();
// let yContainer = new PIXI.Container();
export const createLabelY = (canvasHeight: number) => {
  /*****************
  yContainer.destroy();
  yContainer = new PIXI.Container();

  const rectangle = new SmoothGraphics();
  rectangle.beginFill(0x1d1929).drawRect(-1, 0, 45, canvasHeight).endFill();
  yContainer.addChild(rectangle);

  PIXI.BitmapFont.from(
    "TitleFont",
    {
      fontFamily: "Arial",
      fontSize: 12,
      strokeThickness: 2,
      fill: "#7F779E",
      align: "center",
    },
    {
      chars: "-+.1234567890",
    }
  );
  const yLabels: number[] = [5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5];

  let yAxis = 0;

  yLabels.map((n: number) => {
    const bText = new PIXI.BitmapText((n * GRID_SIZE_LABEL_DISTANCE).toFixed(0), {
      fontName: "TitleFont",
    });
    bText.y = yAxis;
    yContainer.addChild(bText);
    yAxis += GRID_SIZE_LABEL_DISTANCE;
  });
  renderer.stage.addChild(yContainer);
  yContainer.position.y = -4;
  *****************/
};

export const createLabelX = (canvasHeight: number, canvasWidth: number) => {
  /*****************
  console.log("canvasWidth::", canvasWidth);
  const rectangle = new SmoothGraphics();
  rectangle.beginFill(0x1d1929).drawRect(0, 0, canvasWidth, 50).endFill();
  xContainer.addChild(rectangle);

  PIXI.BitmapFont.from(
    "TitleFont",
    {
      fontFamily: "Arial",
      fontSize: 12,
      strokeThickness: 2,
      fill: "#7F779E",
      align: "center",
    },
    {
      chars: "-+.1234567890Unit| ",
    }
  );

  let xAxis = 0;
  const bText = new PIXI.BitmapText("Unit       |", {
    fontName: "TitleFont",
  });
  xContainer.addChild(bText);
  // xAxis += 70;
  xAxis += canvasWidth / 11;
  for (let n: number = 1; n < 10; n++) {
    const bText = new PIXI.BitmapText(n.toString(), {
      fontName: "TitleFont",
    });
    bText.x = xAxis;
    xContainer.addChild(bText);
    xAxis += canvasWidth / 11;
  }
  xContainer.position.x = 40;
  xContainer.position.y = canvasHeight - 20;
  renderer.stage.addChild(xContainer);
  *****************/
};

 
export const initFloat = (num: number | string) => {
  const no = Number(num);
  const isDecimal = store.getState().config.config.currency.decimal; 
  if (!isDecimal) {
    return numeral(parseInt(no.toString())).format("0,0");
  } else {
    return numeral(no).format("0,0.[00]");
  }
};

export const gamePanelsTheme = (gamePlay: string) => {
  let style = {
    primary: "#fb8111",
    secondary: "#0fc943",
    shadowPrimary: "rgb(26, 82, 141) 0px -3px 0px inset",
    shadowSecondary: "rgb(0, 102, 26) 0px -3px 0px inset",
  };
  switch (gamePlay) {
    case "crash1917":
      style = {
        primary: "#FF9E00",
        secondary: "#7B2CBF",
        shadowPrimary: "inset 0px -3px 0px #FF6D00",
        shadowSecondary: "inset 0px -3px 0px #3C096C",
      };

      break;

    case "bloodburst":
      style = {
        primary: "#EF2D67",
        secondary: "#4091C9",
        shadowPrimary: "inset 0px -3px 0px #65010C",
        shadowSecondary: "inset 0px -3px 0px #033270",
      };
      break;

    case "crashghostly":
      style = {
        primary: "#7364D2",
        secondary: "#F348A9",
        shadowPrimary: "inset 0px -3px 0px #432780",
        shadowSecondary: "inset 0px -3px 0px #B939D0",
      };
      break;
    case "crashwitch":
      style = {
        primary: "#FFD93D",
        secondary: "#722E9A",
        shadowPrimary: "inset 0px -3px 0px #F5BD1F",
        shadowSecondary: "inset 0px -3px 0px #5D2689",
      };
      break;

    case "crash3dx":
      style = {
        primary: "#009EFF",
        secondary: "#6851E4",
        shadowPrimary: "inset 0px -3px 0px #006EB2",
        shadowSecondary: "inset 0px -3px 0px #48389F",
      };
      break;

    default:
      style = {
        primary: "#fb8111",
        secondary: "#0fc943",
        shadowPrimary: "inset 0px -3px 0px #FF6D00",
        shadowSecondary: "rgb(0, 102, 26) 0px -3px 0px inset",
      };
      break;
  }

  return style;
};
