import { Stack } from "@mui/material";
import { useState } from "react";
import { DetailsIconButton } from "../../../../svgComponent";
// import { InformationModal } from "src/modals/InformationModal";
import InformationModal from "src/modals/InformationModal";

const InfoButton = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Stack sx={{ cursor: "pointer" }}>
      <Stack onClick={() => setOpen(!open)}>
        <DetailsIconButton />
      </Stack>
      <InformationModal open={open} setOpen={setOpen} />
    </Stack>
  );
};

export default InfoButton;
