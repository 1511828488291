import store, { setPlayer } from "src/store/store";

interface INicknameResultHandler {
  nickname: string;
}
export const nicknameResultHandler = (eventObj: INicknameResultHandler) => {
  const { nickname } = eventObj;
  store.dispatch(setPlayer({ nickname }));

  // //-- temporary 2023-06-23
  // const minBetAmount = 1;
  // const maxBetAmount = 1000;
  // const game = store.getState().game.game;
  // store.dispatch(setGame({ ...game, minBetAmount, maxBetAmount }));
};
