import { Stack } from "@mui/material";
import CurrentShareValue from "./components/CurrentShareValue";
import ActiveUsers from "./components/ActiveUsers";
import React from "react";

const Footer = () => {
  return (
    <Stack
      dir={"ltr"}
      direction={"row"}
      justifyContent={"space-between"}
      px={2}
      pt={["21px", null, null]}
      position={'absolute'}
      bottom={10}
      width={'100%'}
    >
      <ActiveUsers />
      <CurrentShareValue />
    </Stack>
  );
};

export  default React.memo(Footer)