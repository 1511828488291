import { Box, Stack } from "@mui/material";
import { InputComponent } from "./components/InputComponent";
import { BetFormControl, BetFormLabel, BetPlacerStack } from "./@styles";
import DoubleButton from "./components/DoubleButton";
import HalfButton from "./components/HalfButton";
import { useTranslation } from "react-multi-lang";
import React from "react";

const BetAmountComponent = () => {
  const t = useTranslation();
  return (
    <BetPlacerStack>
      <Box width={"100%"}>
        <BetFormControl>
          <BetFormLabel>{t("translate.amount")}</BetFormLabel>
          <InputComponent />
        </BetFormControl>
      </Box>
      <Stack direction={"row"} gap={1}>
        <HalfButton />
        <DoubleButton />
      </Stack>
    </BetPlacerStack>
  );
};

export default React.memo(BetAmountComponent);
