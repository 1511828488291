import { Box, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { DashboardScreen } from "src/components/tradingInformation/components/DashboardScreen";
import { HowToPlayScreen } from "src/components/tradingInformation/components/HowToPlayScreen";
import { InfoMainPage } from "src/components/tradingInformation/components/InfoMainPage";
import { StakeScreen } from "src/components/tradingInformation/components/StakeScreen";
import { ModalComponent } from "../components/MuiComponents/ModalComponent";
import React from "react";
import { useSelector } from "react-redux";
import { getConfig } from "src/store/store";
import { GameBoardComponent } from "src/components/tradingInformation/components/GameBoardModal/GameBoardComponent";
import { InformationModalType } from "src/components/tradingInformation/types/propsType";
import { PlayerActionsModalComponent } from "src/components/tradingInformation/components/PlayerActions/PlayerActionsModalComponent";
import { GameTabsModalComponent } from "src/components/tradingInformation/components/GameTabs/GameTabsModalComponent";

interface InformationModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const InformationModal = ({ open, setOpen }: InformationModalProps) => {
  const isRtl = useSelector(getConfig).config.lang.rtl;
  const [activeScreen, setActiveScreen] =
    useState<InformationModalType>("info");

  return (
    <ModalComponent
      open={open}
      onClose={() => setOpen(!open)}
      width={["90%", "500px", "500px"]}
      height={"fit-content"}
    >
      <Stack
        dir={isRtl ? "rtl" : "ltr"}
        sx={{
          minHeight: "400px",
        }}
      >
        <Box
          className="nonSelect"
          display={"flex"}
          flexDirection={"column"}
          gap={"12px"}
          height={"fit-content"}
          maxHeight={["65vh", "65vh", "70vh"]}
          p={2}
          pt={0}
        >
          {activeScreen === "info" && (
            <InfoMainPage
              activePage={activeScreen}
              setActivePage={setActiveScreen}
              setOpen={setOpen}
            />
          )}
          {activeScreen === "howToPlay" && (
            <HowToPlayScreen
              activePage={activeScreen}
              setActivePage={setActiveScreen}
            />
          )}

          {activeScreen === "stake" && (
            <StakeScreen
              activePage={activeScreen}
              setActivePage={setActiveScreen}
            />
          )}

          {activeScreen === "dashboard" && (
            <DashboardScreen
              activePage={activeScreen}
              setActivePage={setActiveScreen}
            />
          )}
          {activeScreen === "Board" && (
            <GameBoardComponent
              activePage={activeScreen}
              setActivePage={setActiveScreen}
            />
          )}
          {activeScreen === "Actions" && (
            <PlayerActionsModalComponent
              activePage={activeScreen}
              setActivePage={setActiveScreen}
            />
          )}
          {activeScreen === "Tabs" && (
            <GameTabsModalComponent
              activePage={activeScreen}
              setActivePage={setActiveScreen}
            />
          )}
        </Box>{" "}
      </Stack>
    </ModalComponent>
  );
};

export default React.memo(InformationModal);
