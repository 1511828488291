import { currentTime } from "src/utils/currentTime";
import { colors } from "src/utils/theme";
import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { RiTimeFill } from "react-icons/ri";


export const TimeComponent = () => {
    const theme = useTheme()

    const [clock, setClock] = useState<string>('00:00')
    setInterval(() => {
        setClock(currentTime())
    }, 10000)
    useEffect(() => {
        setClock(currentTime())
    }, [])

    return (
        <Stack
            className={'nonSelect'}
            direction={'row'}
            sx={{ gap: '4px', alignItems: 'center' }}
        >
            <RiTimeFill
                color={
                    theme.palette.mode === 'dark' ? colors.gray[5] : colors.gray[9]
                }
                size={16}
            />
            <Typography
                component={'span'}
                variant={'bodySmall'}
                fontWeight={'700 !important'}
                fontSize={'14px'}
                fontFamily={'Nunito Sans,sans-serif !important'}
                color={
                    theme.palette.mode === 'dark' ? colors.gray[8] : colors.gray[6]
                }
            >
                {clock}
            </Typography>
        </Stack>
    )
}
