import {createSlice} from "@reduxjs/toolkit";


export const toastSlice = createSlice({
    name: "toast",
    initialState: {toastMessage: "", toastType: "", amount: 0},
    reducers: {
        setToast(state, Action) {
            state.toastMessage = Action?.payload.toastMessage;
            state.toastType = Action?.payload.toastType;
            state.amount = Action?.payload.amount;
        },
    },
});
