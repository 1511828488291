import { PayloadAction, createSlice } from "@reduxjs/toolkit"; 
import { initialChat, IChatState } from "../@types";

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialChat,
  reducers: {
    setChat(state, action: PayloadAction<IChatState>) {
      console.log("Action.payload::", action.payload);
      state.push(action.payload);
    },
  },
});
